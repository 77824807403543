// Constants for localStorage keys
export const CLD_SEARCH_STATE_KEY = 'cld_search_state';

interface SearchState {
    searchTerm: string;
    isDecisionSearch: boolean;
    formData: Record<string, any>;
}

// Save search state to localStorage
export const saveSearchState = (state: SearchState) => {
    try {
        // Only save text search related values
        const stateToSave = {
            searchTerm: state.searchTerm || '',
            isDecisionSearch: state.isDecisionSearch,
            formData: {
                // Only save text-based form data
                term: state.formData.term || '',
                'Document Number': state.formData['Document Number'] || '',
                documentType: state.formData.documentType || '',
                title: state.formData.title || '',
            },
        };
        localStorage.setItem(CLD_SEARCH_STATE_KEY, JSON.stringify(stateToSave));
    } catch (error) {
        console.error('Error saving search state:', error);
    }
};

// Load search state from localStorage
export const loadSearchState = (): SearchState | null => {
    try {
        const savedState = localStorage.getItem(CLD_SEARCH_STATE_KEY);
        if (!savedState) return null;
        return JSON.parse(savedState);
    } catch (error) {
        console.error('Error loading search state:', error);
        return null;
    }
};

// Clear search state from localStorage
export const clearSearchState = () => {
    try {
        localStorage.removeItem(CLD_SEARCH_STATE_KEY);
    } catch (error) {
        console.error('Error clearing search state:', error);
    }
};
