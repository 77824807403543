import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { TState } from '../../../RootReducer';
import { Dispatch } from 'redux';
import { OCldDashboard } from '../../../Features/CldDashboard/redux-config';
import { ILegalFinding } from '../../../Features/CLDDocuments/@types';
import { Link, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import Entry from '../Entry';
import THEME from '../../../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { HEADER_HEIGHT } from '../../RootLayout';
import ClearIcon from '@material-ui/icons/Clear';
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    Fab,
    LinearProgress,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ILegalFindingsFilter } from '../../../Features/CldDashboard/dashboard-types';
import { DEFAULT_LEGAL_FINDINGS_FILTER } from '../../../Features/CldDashboard/dashboard-constants';
import { CldField } from '../../../Services/CldFields.types';
import { CldFieldService } from '../../../Services/CldFields.service';
import StopIcon from '@material-ui/icons/Stop';
import { ILegalFindingsFacets } from '../../../Features/CldDashboard/cldDashboard-reducer';

const BORDER_STYLE = `1px dashed ${fade('#000', 0.2)}`;

// Define column mapping between display names and data fields
interface IColumnMapping {
    displayName: string;
    field: string;
    filter?: boolean;
    filterType?: 'select' | 'date' | 'text' | 'custom';
    options?: { label: string; value: string; disabled?: boolean }[];
    width?: number;
}

interface IStateProps {
    legalFindings: Array<ILegalFinding>;
    filter: ILegalFindingsFilter;
    pageNumber: number;
    total: number;
    loading: boolean;
    isDownloading: boolean;
    cldFields: Array<CldField>;
    hasMore: boolean;
    facets: ILegalFindingsFacets;
}
interface IDispatchProps {
    fetchLegalFindings: () => any;
    setFilter: (filter: ILegalFindingsFilter) => any;
    clearFilter: () => any;
    setPageNumber: (pageNumber: number) => any;
    downloadLegalFindings: () => any;
    abortDownload: () => any;
    fetchInitialFacets: () => any;
}

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
        },
        row: {
            display: 'flex',
            borderBottom: BORDER_STYLE,
            padding: `${theme.spacing.unit}px 0`,
            minWidth: 'fit-content',
            '&:hover': {
                backgroundColor: fade(THEME.Colors.Primary.light, 0.1),
            },
        },
        headerRow: {
            display: 'flex',
            flexDirection: 'column',
            borderBottom: BORDER_STYLE,
            backgroundColor: THEME.Colors.Grayscale.White,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            flex: '0 0 auto',
            minWidth: 'fit-content',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: '-100vw',
                bottom: 0,
                width: '100vw',
                backgroundColor: 'inherit',
                borderBottom: BORDER_STYLE,
                zIndex: -1,
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-100vw',
                bottom: 0,
                width: '100vw',
                backgroundColor: 'inherit',
                borderBottom: BORDER_STYLE,
                zIndex: -1,
            },
        },
        headerTitle: {
            display: 'flex',
            borderBottom: BORDER_STYLE,
        },
        headerFilter: {
            display: 'flex',
        },
        column: {
            flex: '0 0 200px',
            minWidth: '200px',
            maxWidth: '200px',
            borderRight: BORDER_STYLE,
            padding: `0 ${theme.spacing.unit * 2}px`,
            '&:last-child': {
                borderRight: 'none',
            },
            '& > p': {
                maxHeight: '4.5em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                lineHeight: '1.5em',
            },
            '&[data-type="date"]': {
                flex: '0 0 300px',
                minWidth: '300px',
                maxWidth: '300px',
            },
            '&[data-type="custom"]': {
                flex: '0 0 300px',
                minWidth: '300px',
                maxWidth: '300px',
            },
        },
        container: {
            flex: 1,
            overflowY: 'auto',
            overflowX: 'auto',
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing.unit * 4,
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        },
        headerColumn: {
            backgroundColor: THEME.Colors.Grayscale.White,
        },
        filterField: {
            '& .MuiSelect-select': {
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& .MuiMenu-paper': {
                width: '200px',
            },
            '& .MuiMenuItem-root': {
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        shrink: {
            transform: 'translate(0px, 6px) scale(0.75) !important',
            fontWeight: theme.typography.fontWeightMedium,
        },
        buttonProgress: {
            color: THEME.Colors.Grayscale.White,
        },
        dateRangeContainer: {
            display: 'flex',
            gap: theme.spacing.unit * 2,
            width: '100%',
        },
        dateField: {
            flex: 1,
            '& .MuiInputBase-root': {
                height: 40,
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(12px, 12px) scale(1)',
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(12px, 6px) scale(0.75)',
            },
        },
        headerActions: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing.unit,
            borderBottom: BORDER_STYLE,
        },
        clearButton: {
            position: 'fixed',
            top: HEADER_HEIGHT + theme.spacing.unit,
            right: theme.spacing.unit * 2,
            zIndex: 2,
            backgroundColor: THEME.Colors.Grayscale.White,
            boxShadow: theme.shadows[2],
            '&:hover': {
                backgroundColor: fade(THEME.Colors.Grayscale.White, 0.9),
                boxShadow: theme.shadows[4],
            },
        },
        downloadFab: {
            position: 'fixed',
            bottom: theme.spacing.unit * 4,
            right: theme.spacing.unit * 4,
            backgroundColor: THEME.Colors.Primary.main,
            color: THEME.Colors.Grayscale.White,
            '&:hover': {
                backgroundColor: THEME.Colors.Primary.dark,
            },
        },
        numberRangeContainer: {
            display: 'flex',
            gap: theme.spacing.unit * 2,
            width: '100%',
        },
        numberField: {
            flex: 1,
            '& .MuiInputBase-root': {
                height: 40,
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(12px, 12px) scale(1)',
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(12px, 6px) scale(0.75)',
            },
        },
        stopButton: {
            backgroundColor: THEME.Colors.Error,
            color: THEME.Colors.Grayscale.White,
            '&:hover': {
                backgroundColor: fade(THEME.Colors.Error, 0.8),
            },
        },
        downloadButtonContent: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        stopIcon: {
            position: 'absolute',
            inset: 0,
            width: 24,
            height: 24,
            margin: 'auto',
        },
        linearProgress: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
        badgeCount: {
            position: 'absolute',
            top: -24,
            right: -18,
            backgroundColor: THEME.Colors.Primary.dark,
            color: THEME.Colors.Grayscale.White,
            borderRadius: '12px',
            minWidth: '24px',
            height: '24px',
            padding: '0 6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
            border: `1px solid ${THEME.Colors.Grayscale.White}`,
            zIndex: 1,
            transform: 'translateX(40%)',
        },
    });

const generateEntryColumns = (cldFields: CldField[], facets: ILegalFindingsFacets): IColumnMapping[] => {
    const getFieldName = (id: string, type: string) => {
        const field = cldFields.find(f => f.id === id && f.type === type);
        return field ? `${field.name}[${field.id}]` : id;
    };

    const createOptions = (facetData: Array<{ key: string | null; doc_count: number }> = [], type: string) => {
        return [
            { label: 'All', value: '' },
            ...facetData
                .filter(f => f.key !== null && f.key !== '') // Filter out null and empty values
                .map(f => ({
                    label: type === 'raw' ? `${f.key} (${f.doc_count})` : getFieldName(String(f.key), type) + ` (${f.doc_count})`,
                    value: String(f.key)
                }))
        ];
    };

    return [
        { displayName: 'Title', field: 'Title', filter: true, filterType: 'text' },
        { displayName: 'Legal Finding Number', field: 'Finding', filter: true, filterType: 'text' },
        { displayName: 'Document Number', field: 'Document Number', filter: true, filterType: 'text' },
        { displayName: 'Date of Decision', field: 'dateCreated', filter: true, filterType: 'date', width: 400 },
        {
            displayName: 'Language',
            field: 'language',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.cldFieldLanguageId, 'language')
        },
        {
            displayName: 'Situation/case',
            field: 'situationCase',
            filter: true,
            filterType: 'select',
            options: [
                { label: 'All', value: '' },
                { label: '-- Situations --', value: 'situation_divider', disabled: true },
                ...facets.ltdDoc_decision_situationNameCardId
                    .filter(f => f.key !== null && f.key !== '')
                    .map(f => ({
                        label: getFieldName(String(f.key), 'situationNameCard') + ` (${f.doc_count})`,
                        value: `situation:${f.key}`
                    })),
                { label: '-- Cases --', value: 'case_divider', disabled: true },
                ...facets.ltdDoc_decision_caseNameCardId
                    .filter(f => f.key !== null && f.key !== '')
                    .map(f => ({
                        label: getFieldName(String(f.key), 'caseNameCard') + ` (${f.doc_count})`,
                        value: `case:${f.key}`
                    }))
            ]
        },
        {
            displayName: 'Decision Type',
            field: 'ltdDoc._decision.typeOfDecisionId',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.ltdDoc_decision_typeOfDecisionId, 'typeOfDecision')
        },
        {
            displayName: 'Source',
            field: 'ltdDoc._decision.documentSourceId',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.ltdDoc_source, 'raw')
        },
        {
            displayName: 'Progress',
            field: 'progress',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.progress, 'raw')
        },
        {
            displayName: 'Importance',
            field: 'Importance01',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.Importance01, 'raw')
        },
        {
            displayName: 'Confidentiality',
            field: 'Confidentiality',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.Confidentiality, 'raw')
        },
        { displayName: 'Number of Words', field: 'words', filter: true, filterType: 'text' },
        {
            displayName: 'Appeal relationship',
            field: 'ltdDoc._decision["Applicable Appeal"]',
            filter: true,
            filterType: 'select',
            options: createOptions(facets.ltdDoc_decision_ApplicableAppeal, 'raw')
        },
        {
            displayName: 'Appeal relationship document number',
            field: 'ltdDoc._decision["Appeal Doc ID"]',
            filter: true,
            filterType: 'text',
        },
    ];
};

interface LegalFindingsProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps {}

export const LegalFindings: FC<LegalFindingsProps> = props => {
    const {
        classes,
        setFilter,
        clearFilter,
        filter,
        pageNumber,
        setPageNumber,
        total,
        fetchLegalFindings,
        legalFindings,
        downloadLegalFindings,
        isDownloading,
        cldFields,
        hasMore,
        abortDownload,
        loading,
        facets,
        fetchInitialFacets,
    } = props;

    // Generate ENTRY_COLUMNS using cldFields and facets
    const ENTRY_COLUMNS = generateEntryColumns(cldFields, facets);

    useEffect(() => {
        fetchInitialFacets();
        setPageNumber(0);
        fetchLegalFindings();
    }, []);

    const loadMoreTask = useAsyncTask(async () => {
        setPageNumber(pageNumber + 1);
        await fetchLegalFindings();
    });

    const handleFilterChange = (field: string) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const newFilter: Record<string, any> = {
            ...filter,
        };

        const value = event.target.value;

        if (field === 'language') {
            newFilter.language = value ? [value] : [];
        } else if (field === 'ltdDoc._decision.typeOfDecisionId') {
            newFilter[field] = value;
        } else if (field === 'appealRelationship') {
            // Handle appeal relationship filtering
            if (value === 'Appeals judgment') {
                newFilter['ltdDoc._decision["Applicable Appeal"]'] = 'Appeals judgment';
            } else if (value === 'Impugned decision') {
                newFilter['ltdDoc._decision["Applicable Appeal"]'] = 'Impugned decision';
            } else {
                // Clear appeal relationship filters if "All" is selected
                delete newFilter['ltdDoc._decision["Applicable Appeal"]'];
            }
        } else {
            newFilter[field] = value;
        }

        setFilter(newFilter);
    };

    const handleDateChange = (field: 'fromDate' | 'toDate') => (date: Date | null) => {
        const newFilter = { ...filter };

        if (!newFilter.dateCreated) {
            newFilter.dateCreated = { type: 'range' };
        }

        if (field === 'fromDate') {
            if (date) {
                newFilter.dateCreated.gte = moment(date)
                    .startOf('day')
                    .valueOf();
            } else {
                delete newFilter.dateCreated.gte;
            }
        } else {
            if (date) {
                newFilter.dateCreated.lte = moment(date)
                    .endOf('day')
                    .valueOf();
            } else {
                delete newFilter.dateCreated.lte;
            }
        }

        // If both dates are cleared, remove the dateCreated filter
        if (!newFilter.dateCreated.gte && !newFilter.dateCreated.lte) {
            delete newFilter.dateCreated;
        }

        setFilter(newFilter);
    };

    const renderFilter = (column: IColumnMapping) => {
        if (!column.filter) return null;

        switch (column.filterType) {
            case 'select':
                return (
                    <FormControl variant='filled' fullWidth>
                        <InputLabel classes={{ shrink: classes.shrink }}>{column.displayName}</InputLabel>
                        <Select
                            value={filter[column.field] || ''}
                            onChange={handleFilterChange(column.field)}
                            className={classes.filterField}
                        >
                            {column.options &&
                                column.options.map(option => (
                                    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                );
            case 'date':
                return (
                    <div className={classes.dateRangeContainer}>
                        <InlineDatePicker
                            keyboard
                            clearable
                            disableFuture
                            name='fromDate'
                            label='From'
                            fullWidth
                            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            views={['year', 'month', 'day']}
                            maxDate={
                                filter.dateCreated && filter.dateCreated.lte
                                    ? moment(filter.dateCreated.lte)
                                    : new Date()
                            }
                            keyboardIcon={<i className='material-icons'>event</i>}
                            format='DD.MM.YYYY'
                            value={filter.dateCreated && filter.dateCreated.gte ? moment(filter.dateCreated.gte) : null}
                            onChange={handleDateChange('fromDate')}
                            className={classes.dateField}
                            InputProps={{
                                className: classes.filterField,
                            }}
                        />
                        <InlineDatePicker
                            keyboard
                            clearable
                            disableFuture
                            name='toDate'
                            label='To'
                            fullWidth
                            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            views={['year', 'month', 'day']}
                            minDate={
                                filter.dateCreated && filter.dateCreated.gte ? moment(filter.dateCreated.gte) : null
                            }
                            maxDate={new Date()}
                            keyboardIcon={<i className='material-icons'>event</i>}
                            format='DD.MM.YYYY'
                            value={filter.dateCreated && filter.dateCreated.lte ? moment(filter.dateCreated.lte) : null}
                            onChange={handleDateChange('toDate')}
                            className={classes.dateField}
                            InputProps={{
                                className: classes.filterField,
                            }}
                        />
                        {filter.dateCreated && (
                            <Tooltip title='Clear date filter'>
                                <IconButton
                                    onClick={() => {
                                        const newFilter = { ...filter };
                                        delete newFilter.dateCreated;
                                        setFilter(newFilter);
                                    }}
                                    style={{ alignSelf: 'center' }}
                                >
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            case 'text':
                if (column.field === 'words') {
                    return (
                        <div className={classes.numberRangeContainer}>
                            <TextField
                                label='Min Words'
                                type='number'
                                value={(filter.words && filter.words.gte) || ''}
                                onChange={e => {
                                    const value = e.target.value;
                                    const newFilter = { ...filter };
                                    if (!newFilter.words) {
                                        newFilter.words = {};
                                    }
                                    if (value) {
                                        newFilter.words.gte = parseInt(value);
                                    } else {
                                        delete newFilter.words.gte;
                                    }
                                    if (Object.keys(newFilter.words).length === 0) {
                                        delete newFilter.words;
                                    }
                                    setFilter(newFilter);
                                }}
                                className={classes.numberField}
                                InputLabelProps={{
                                    classes: { shrink: classes.shrink },
                                }}
                                inputProps={{ min: 0 }}
                            />
                            <TextField
                                label='Max Words'
                                type='number'
                                value={(filter.words && filter.words.lte) || ''}
                                onChange={e => {
                                    const value = e.target.value;
                                    const newFilter: any = { ...filter };
                                    if (!newFilter.words) {
                                        newFilter.words = {};
                                    }
                                    if (value) {
                                        newFilter.words.lte = parseInt(value);
                                    } else {
                                        delete newFilter.words.lte;
                                    }
                                    if (Object.keys(newFilter.words).length === 0) {
                                        delete newFilter.words;
                                    }

                                    setFilter(newFilter);
                                }}
                                className={classes.numberField}
                                InputLabelProps={{
                                    classes: { shrink: classes.shrink },
                                }}
                                inputProps={{ min: filter.words && filter.words.gte ? filter.words.gte : 0 }}
                            />
                            {filter.words && (
                                <Tooltip title='Clear word count filter'>
                                    <IconButton
                                        onClick={() => {
                                            const newFilter = { ...filter };
                                            delete newFilter.words;
                                            setFilter(newFilter);
                                        }}
                                        style={{ alignSelf: 'center' }}
                                    >
                                        <ClearIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    );
                }
                return (
                    <TextField
                        label={column.displayName}
                        value={filter[column.field] || ''}
                        onChange={handleFilterChange(column.field)}
                        className={classes.filterField}
                        InputLabelProps={{
                            classes: { shrink: classes.shrink },
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.headerRow}>
                    <div className={classes.headerFilter}>
                        {ENTRY_COLUMNS.map(column => (
                            <div
                                key={`filter-${column.field}`}
                                className={classes.column}
                                data-type={column.filterType}
                                style={
                                    column.filterType === 'date' || column.filterType === 'custom'
                                        ? { width: '300px', flex: '0 0 300px' }
                                        : { width: '200px', flex: '0 0 200px' }
                                }
                            >
                                {renderFilter(column)}
                            </div>
                        ))}
                    </div>
                    {loading && <LinearProgress className={classes.linearProgress} color='primary' />}
                </div>
                {legalFindings.map((finding, index) =>
                    finding ? (
                        <div key={finding.id || index} className={classes.row}>
                            {ENTRY_COLUMNS.map(column => (
                                <div
                                    key={`${finding.id}-${column.field}`}
                                    className={classes.column}
                                    data-type={column.filterType}
                                    style={
                                        column.filterType === 'date' || column.filterType === 'custom'
                                            ? { width: '300px', flex: '0 0 300px' }
                                            : { width: '200px', flex: '0 0 200px' }
                                    }
                                >
                                    <Entry>
                                        {column.field === 'situationCase' ? (
                                            (() => {
                                                const caseId = _.get(finding, 'ltdDoc._decision.caseNameCardId', '');
                                                const caseName = CldFieldService.resolveCldField(cldFields, caseId);
                                                if (caseName && caseName !== 'None (Situation only)') {
                                                    return caseName;
                                                }
                                                const situationId = _.get(
                                                    finding,
                                                    'ltdDoc._decision.situationNameCardId',
                                                    ''
                                                );
                                                const situationName = CldFieldService.resolveCldField(
                                                    cldFields,
                                                    situationId
                                                );
                                                if (situationName) {
                                                    return situationName;
                                                }

                                                const _caseName = _.get(finding, 'ltdDoc.caseName_en', '');
                                                return _caseName || '-';
                                            })()
                                        ) : column.field === 'progress' ? (
                                            <Typography
                                                style={{
                                                    color:
                                                        finding.progress === 'Finalised'
                                                            ? THEME.Colors.Primary.main
                                                            : 'inherit',
                                                }}
                                            >
                                                {_.get(finding, column.field, '-')}
                                            </Typography>
                                        ) : column.field === 'ltdDoc._decision.documentSourceId' ||
                                          column.field === 'ltdDoc._decision.typeOfDecisionId' ? (
                                            CldFieldService.resolveCldField(cldFields, _.get(finding, column.field, ''))
                                        ) : column.filterType === 'date' ? (
                                            moment(_.get(finding, column.field)).format('DD.MMM.YYYY')
                                        ) : column.field === 'Title' ? (
                                            <Link to={`/clddoc/${finding.id}`} style={{ color: '#505050' }}>
                                                {_.get(finding, column.field, '-')}
                                            </Link>
                                        ) : (
                                            _.get(finding, column.field, '-')
                                        )}
                                    </Entry>
                                </div>
                            ))}
                        </div>
                    ) : null
                )}
                {hasMore && (
                    <Button onClick={loadMoreTask.run} disabled={loadMoreTask.status === 'PROCESSING'} fullWidth>
                        {loadMoreTask.status === 'PROCESSING' ? (
                            <CircularProgress size={24} color='primary' />
                        ) : (
                            'Load more'
                        )}
                    </Button>
                )}
            </div>
            <Tooltip title='Clear all filters'>
                <IconButton className={classes.clearButton} onClick={clearFilter} aria-label='clear filters'>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={`Download filtered legal findings (${total})`}>
                <Fab
                    className={`${isDownloading ? classes.stopButton : ''} ${classes.downloadFab}`}
                    onClick={
                        isDownloading
                            ? e => {
                                  e.stopPropagation();
                                  abortDownload();
                              }
                            : downloadLegalFindings
                    }
                    aria-label={`download ${total} legal findings`}
                >
                    <div className={classes.downloadButtonContent}>
                        {isDownloading ? (
                            <>
                                <CircularProgress size={28} className={classes.buttonProgress} />
                                <StopIcon fontSize='small' className={classes.stopIcon} />
                            </>
                        ) : (
                            <>
                                <GetAppIcon />
                                {total > 0 && <div className={classes.badgeCount}>{total}</div>}
                            </>
                        )}
                    </div>
                </Fab>
            </Tooltip>
        </div>
    );
};

const mapStateToProps = (state: TState): IStateProps => ({
    legalFindings: state.CldDashboard.legalFindings.legalFindings || [],
    filter: state.CldDashboard.legalFindings.filter || DEFAULT_LEGAL_FINDINGS_FILTER,
    pageNumber: state.CldDashboard.legalFindings.pageNumber || 0,
    total: state.CldDashboard.legalFindings.total || 0,
    loading: state.CldDashboard.legalFindings.loading,
    isDownloading: state.CldDashboard.legalFindings.isDownloading,
    cldFields: state.CldFields.cldFields,
    hasMore: state.CldDashboard.legalFindings.hasMore || false,
    facets: state.CldDashboard.legalFindings.facets || {
        progress: [],
        Confidentiality: [],
        Importance01: [],
        cldFieldLanguageId: [],
        ltdDoc_source: [],
        ltdDoc_decision_typeOfDecisionId: [],
        ltdDoc_decision_documentSourceId: [],
        ltdDoc_decision_situationNameCardId: [],
        ltdDoc_decision_caseNameCardId: [],
        ltdDoc_decision_ApplicableAppeal: []
    },
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    fetchLegalFindings: () => dispatch(OCldDashboard.fetchLegalFindings()),
    setFilter: (filter: ILegalFindingsFilter) => dispatch(OCldDashboard.setLegalFindingsFilter(filter)),
    clearFilter: () => dispatch(OCldDashboard.clearLegalFindingsFilter()),
    setPageNumber: (pageNumber: number) =>
        dispatch({ type: 'CLD_DASHBOARD_LEGAL_FINDINGS_SET_PAGE_NUMBER', data: pageNumber }),
    downloadLegalFindings: () => dispatch(OCldDashboard.downloadLegalFindings()),
    abortDownload: () => dispatch(OCldDashboard.abortDownloadLegalFindings()),
    fetchInitialFacets: () => dispatch(OCldDashboard.fetchInitialFacets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(LegalFindings));
