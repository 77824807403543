import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { TState } from '../../../RootReducer';
import { Dispatch } from 'redux';
import { OCldDashboard } from '../../../Features/CldDashboard/redux-config';
import { Link, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import Entry from '../Entry';
import { fade } from '@material-ui/core/styles/colorManipulator';
import THEME from '../../../Resources/Theme';
import useAsyncTask from '../../../Hooks/useAsyncTask';
import { InlineDatePicker } from 'material-ui-pickers';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    Fab,
    LinearProgress,
} from '@material-ui/core';
import { HEADER_HEIGHT } from '../../RootLayout';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DEFAULT_DECISION_FILTER } from '../../../Features/CldDashboard/dashboard-constants';
import { IDecisionFilter } from '../../../Features/CldDashboard/dashboard-types';
import { CldField } from '../../../Services/CldFields.types';
import StopIcon from '@material-ui/icons/Stop';
import { CldFieldService } from '../../../Services/CldFields.service';

// Add the facets interface
interface IFacets {
    confidentiality: Array<{ key: string; doc_count: number }>;
    _decision_languageId: Array<{ key: string; doc_count: number }>;
    _decision_typeOfDecisionId: Array<{ key: string; doc_count: number }>;
    _decision_documentSourceId: Array<{ key: string; doc_count: number }>;
    _decision_situationNameCardId: Array<{ key: string; doc_count: number }>;
    _decision_caseNameCardId: Array<{ key: string; doc_count: number }>;
    _decision_ApplicableAppeal: Array<{ key: string | null; doc_count: number }>;
    legalFindings: Array<{ key: boolean | null; doc_count: number }>;
}

interface IColumnMapping {
    displayName: string;
    field: string;
    filter?: boolean;
    filterType?: 'select' | 'date' | 'text' | 'custom';
    options?: { label: string; value: string; disabled?: boolean }[];
    width?: number;
}

const generateEntryColumns = (cldFields: CldField[], facets: IFacets): IColumnMapping[] => {
    // Helper function to get name from cldFields
    const getFieldName = (id: string, type: string) => {
        const field = cldFields.find(f => f.id === id && f.type === type);
        return field ? field.name : id;
    };

    // Helper function to create options from facets
    const createOptions = (facetData: Array<{ key: string | null | boolean; doc_count: number }>, type: string) => {
        return [
            { label: 'All', value: '' },
            ...facetData
                .filter(f => f.key !== null) // Filter out null values
                .map(f => ({
                    label: getFieldName(String(f.key), type) + ` (${f.doc_count})`,
                    value: String(f.key)
                }))
        ];
    };

    return [
        { displayName: 'Title', field: '_decision.title', filter: true, filterType: 'text' },
        { displayName: 'Document Number', field: 'Document Number', filter: true, filterType: 'text' },
        { displayName: 'Date Created', field: '_decision.created', filter: true, filterType: 'date', width: 400 },
        {
            displayName: 'Language',
            field: 'language',
            filter: true,
            filterType: 'select',
            options: createOptions(facets._decision_languageId, 'language')
        },
        {
            displayName: 'Situation/Case',
            field: 'situationCase',
            filter: true,
            filterType: 'select',
            options: [
                { label: 'All', value: '' },
                { label: '-- Situations --', value: 'situation_divider', disabled: true },
                ...facets._decision_situationNameCardId
                    .filter(f => f.key !== null)
                    .map(f => ({
                        label: getFieldName(String(f.key), 'situationNameCard') + ` (${f.doc_count})`,
                        value: `situation:${f.key}`
                    })),
                { label: '-- Cases --', value: 'case_divider', disabled: true },
                ...facets._decision_caseNameCardId
                    .filter(f => f.key !== null)
                    .map(f => ({
                        label: getFieldName(String(f.key), 'caseNameCard') + ` (${f.doc_count})`,
                        value: `case:${f.key}`
                    }))
            ]
        },
        {
            displayName: 'Decision Type',
            field: 'typeOfDecision',
            filter: true,
            filterType: 'select',
            options: createOptions(facets._decision_typeOfDecisionId, 'typeOfDecision')
        },
        {
            displayName: 'Source',
            field: '_decision.documentSourceId',
            filter: true,
            filterType: 'select',
            options: createOptions(facets._decision_documentSourceId, 'documentSource')
        },
        {
            displayName: 'Assessment',
            field: 'assessment',
            filter: true,
            filterType: 'select',
            options: [
                { label: 'All', value: '' },
                ...facets.legalFindings
                    .filter(f => f.key !== null)
                    .map(f => ({
                        label: `${f.key ? 'Yes' : 'No'} (${f.doc_count})`,
                        value: f.key ? 'true' : 'false'
                    }))
            ]
        },
        {
            displayName: 'Appeal Relationship',
            field: '_decision["Applicable Appeal"]',
            filter: true,
            filterType: 'select',
            options: [
                { label: 'All', value: '' },
                ...facets._decision_ApplicableAppeal
                    .filter(f => f.key !== null && f.key !== '')
                    .map(f => ({
                        label: String(f.key) + ` (${f.doc_count})`,
                        value: String(f.key)
                    }))
            ]
        },
        {
            displayName: 'Appeal relationship document number',
            field: '_decision["Appeal Doc ID"]',
            filter: true,
            filterType: 'text',
        },
    ];
};

interface IStateProps {
    decisions: any[];
    filter: IDecisionFilter;
    pageNumber: number;
    total: number;
    loading: boolean;
    isDownloading: boolean;
    cldFields: CldField[];
    hasMore: boolean;
    facets: IFacets;
}

interface IDispatchProps {
    fetchDecisions: () => any;
    setFilter: (filter: IDecisionFilter) => any;
    clearFilter: () => any;
    setPageNumber: (pageNumber: number) => any;
    downloadDecisions: () => any;
    abortDownload: () => any;
    fetchInitialFacets: () => any;
}

const BORDER_STYLE = `1px dashed ${fade('#000', 0.2)}`;

const STYLES = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
        },
        row: {
            display: 'flex',
            borderBottom: BORDER_STYLE,
            padding: `${theme.spacing.unit}px 0`,
            minWidth: 'fit-content',
            '&:hover': {
                backgroundColor: fade(THEME.Colors.Primary.light, 0.1),
            },
        },
        headerRow: {
            display: 'flex',
            flexDirection: 'column',
            borderBottom: BORDER_STYLE,
            backgroundColor: THEME.Colors.Grayscale.White,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            flex: '0 0 auto',
            minWidth: 'fit-content',
            '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: '-100vw',
                bottom: 0,
                width: '100vw',
                backgroundColor: 'inherit',
                borderBottom: BORDER_STYLE,
                zIndex: -1,
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: '-100vw',
                bottom: 0,
                width: '100vw',
                backgroundColor: 'inherit',
                borderBottom: BORDER_STYLE,
                zIndex: -1,
            },
        },
        headerTitle: {
            display: 'flex',
            borderBottom: BORDER_STYLE,
        },
        headerFilter: {
            display: 'flex',
        },
        column: {
            flex: '0 0 200px',
            minWidth: '200px',
            maxWidth: '200px',
            borderRight: BORDER_STYLE,
            padding: `0 ${theme.spacing.unit * 2}px`,
            '&:last-child': {
                borderRight: 'none',
            },
            '& > p': {
                maxHeight: '4.5em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                lineHeight: '1.5em',
            },
            '&[data-type="date"]': {
                flex: '0 0 300px',
                minWidth: '300px',
                maxWidth: '300px',
            },
            '&[data-type="custom"]': {
                flex: '0 0 300px',
                minWidth: '300px',
                maxWidth: '300px',
            },
        },
        container: {
            flex: 1,
            overflowY: 'auto',
            overflowX: 'auto',
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing.unit * 4,
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        },
        headerColumn: {
            backgroundColor: THEME.Colors.Grayscale.White,
        },
        filterField: {
            '& .MuiSelect-select': {
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            '& .MuiMenu-paper': {
                width: '200px',
            },
            '& .MuiMenuItem-root': {
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        shrink: {
            transform: 'translate(0px, 6px) scale(0.75) !important',
            fontWeight: theme.typography.fontWeightMedium,
        },
        buttonProgress: {
            color: THEME.Colors.Grayscale.White,
        },
        dateRangeContainer: {
            display: 'flex',
            gap: theme.spacing.unit * 2,
            width: '100%',
        },
        dateField: {
            flex: 1,
            '& .MuiInputBase-root': {
                height: 40,
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(12px, 12px) scale(1)',
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(12px, 6px) scale(0.75)',
            },
        },
        headerActions: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing.unit,
            borderBottom: BORDER_STYLE,
        },
        clearButton: {
            position: 'fixed',
            top: HEADER_HEIGHT + theme.spacing.unit,
            right: theme.spacing.unit * 2,
            zIndex: 2,
            backgroundColor: THEME.Colors.Grayscale.White,
            boxShadow: theme.shadows[2],
            '&:hover': {
                backgroundColor: fade(THEME.Colors.Grayscale.White, 0.9),
                boxShadow: theme.shadows[4],
            },
        },
        downloadFab: {
            position: 'fixed',
            bottom: theme.spacing.unit * 4,
            right: theme.spacing.unit * 4,
            backgroundColor: THEME.Colors.Primary.main,
            color: THEME.Colors.Grayscale.White,
            '&:hover': {
                backgroundColor: THEME.Colors.Primary.dark,
            },
        },
        stopButton: {
            backgroundColor: THEME.Colors.Error,
            color: THEME.Colors.Grayscale.White,
            '&:hover': {
                backgroundColor: fade(THEME.Colors.Error, 0.8),
            },
        },
        downloadButtonContent: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        stopIcon: {
            position: 'absolute',
            inset: 0,
            height: 24,
            width: 24,
            margin: '0 auto',
        },
        linearProgress: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
        },
        badgeCount: {
            position: 'absolute',
            top: -24,
            right: -18,
            backgroundColor: THEME.Colors.Primary.dark,
            color: THEME.Colors.Grayscale.White,
            borderRadius: '12px',
            minWidth: '24px',
            height: '24px',
            padding: '0 6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
            border: `1px solid ${THEME.Colors.Grayscale.White}`,
            zIndex: 1,
            transform: 'translateX(40%)',
        },
    });

interface DecisionsProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps {}

export const Decisions: FC<DecisionsProps> = props => {
    const {
        classes,
        fetchDecisions,
        decisions,
        setFilter,
        clearFilter,
        filter,
        pageNumber,
        setPageNumber,
        total,
        downloadDecisions,
        isDownloading,
        cldFields,
        hasMore,
        abortDownload,
        loading,
        facets,
        fetchInitialFacets,
    } = props;

    // Generate ENTRY_COLUMNS using cldFields and facets
    const ENTRY_COLUMNS = generateEntryColumns(cldFields, facets);

    useEffect(() => {
        // Fetch initial facets first
        fetchInitialFacets();
        // Then set page number to 0 and fetch decisions
        setPageNumber(0);
        fetchDecisions();
    }, []);

    const loadMoreTask = useAsyncTask(async () => {
        setPageNumber(pageNumber + 1);
        await fetchDecisions();
    });

    const handleFilterChange = (field: string) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const newFilter: Record<string, any> = {
            ...filter,
        };

        const value = event.target.value;

        if (field === 'language') {
            newFilter.language = value ? [value] : [];
        } else if (field === 'appealRelationship') {
            if (value) {
                newFilter['_decision["Applicable Appeal"]'] = value;
            } else {
                delete newFilter['_decision["Applicable Appeal"]'];
            }

            // Also clear any document ID filters when changing relationship type
            delete newFilter['_decision["Appeal Doc ID"]'];
            delete newFilter['_decision["majorityDissentingRelationshipDocId"]'];
        } else {
            newFilter[field] = value;
        }

        setFilter(newFilter);
    };

    const handleDateChange = (field: 'fromDate' | 'toDate') => (date: Date | null) => {
        const newFilter = { ...filter };

        if (!newFilter.dateCreated) {
            newFilter.dateCreated = { type: 'range' };
        }

        if (field === 'fromDate') {
            if (date) {
                newFilter.dateCreated.gte = moment(date)
                    .startOf('day')
                    .valueOf();
            } else {
                delete newFilter.dateCreated.gte;
            }
        } else {
            if (date) {
                newFilter.dateCreated.lte = moment(date)
                    .endOf('day')
                    .valueOf();
            } else {
                delete newFilter.dateCreated.lte;
            }
        }

        // If both dates are cleared, remove the dateCreated filter
        if (!newFilter.dateCreated.gte && !newFilter.dateCreated.lte) {
            delete newFilter.dateCreated;
        }

        setFilter(newFilter);
    };

    const handleAppealRelationshipChange = (event: any) => {
        const value = event.target.value;
        const newFilter = { ...filter };

        if (value) {
            newFilter['_decision["Applicable Appeal"]'] = value;
        } else {
            delete newFilter['_decision["Applicable Appeal"]'];
        }

        setFilter(newFilter);
    };

    const handleAppealDecisionIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newFilter = { ...filter };

        if (value) {
            newFilter['_decision["Appeal Doc ID"]'] = value;
        } else {
            delete newFilter['_decision["Appeal Doc ID"]'];
        }

        setFilter(newFilter);
    };

    const handleClearAppealFilter = () => {
        const newFilter = { ...filter };
        delete newFilter['_decision["Applicable Appeal"]'];
        delete newFilter['_decision["Appeal Doc ID"]'];
        setFilter(newFilter);
    };

    const renderFilter = (column: IColumnMapping) => {
        if (!column.filter) return null;

        switch (column.filterType) {
            case 'select':
                return (
                    <FormControl variant='filled' fullWidth>
                        <InputLabel classes={{ shrink: classes.shrink }}>{column.displayName}</InputLabel>
                        <Select
                            value={filter[column.field] || ''}
                            onChange={handleFilterChange(column.field)}
                            className={classes.filterField}
                        >
                            {column.options &&
                                column.options.map(option => (
                                    <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                );
            case 'date':
                return (
                    <div className={classes.dateRangeContainer}>
                        <InlineDatePicker
                            keyboard
                            clearable
                            disableFuture
                            name='fromDate'
                            label='From'
                            fullWidth
                            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            views={['year', 'month', 'day']}
                            maxDate={
                                filter.dateCreated && filter.dateCreated.lte
                                    ? moment(filter.dateCreated.lte)
                                    : new Date()
                            }
                            keyboardIcon={<i className='material-icons'>event</i>}
                            format='DD.MM.YYYY'
                            value={filter.dateCreated && filter.dateCreated.gte ? moment(filter.dateCreated.gte) : null}
                            onChange={handleDateChange('fromDate')}
                            className={classes.dateField}
                            InputProps={{
                                className: classes.filterField,
                            }}
                        />
                        <InlineDatePicker
                            keyboard
                            clearable
                            disableFuture
                            name='toDate'
                            label='To'
                            fullWidth
                            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                            views={['year', 'month', 'day']}
                            minDate={
                                filter.dateCreated && filter.dateCreated.gte ? moment(filter.dateCreated.gte) : null
                            }
                            maxDate={new Date()}
                            keyboardIcon={<i className='material-icons'>event</i>}
                            format='DD.MM.YYYY'
                            value={filter.dateCreated && filter.dateCreated.lte ? moment(filter.dateCreated.lte) : null}
                            onChange={handleDateChange('toDate')}
                            className={classes.dateField}
                            InputProps={{
                                className: classes.filterField,
                            }}
                        />
                        {filter.dateCreated && (
                            <Tooltip title='Clear date filter'>
                                <IconButton
                                    onClick={() => {
                                        const newFilter = { ...filter };
                                        delete newFilter.dateCreated;
                                        setFilter(newFilter);
                                    }}
                                    style={{ alignSelf: 'center' }}
                                >
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                );
            case 'text':
                return (
                    <TextField
                        label={column.displayName}
                        value={filter[column.field] || ''}
                        onChange={handleFilterChange(column.field)}
                        className={classes.filterField}
                        InputLabelProps={{
                            classes: { shrink: classes.shrink },
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.headerRow}>
                    <div className={classes.headerFilter}>
                        {ENTRY_COLUMNS.map(column => (
                            <div
                                key={`filter-${column.field}`}
                                className={classes.column}
                                data-type={column.filterType}
                                style={
                                    column.filterType === 'date' || column.filterType === 'custom'
                                        ? { width: '300px', flex: '0 0 300px' }
                                        : { width: '200px', flex: '0 0 200px' }
                                }
                            >
                                {renderFilter(column)}
                            </div>
                        ))}
                    </div>
                    {loading && <LinearProgress className={classes.linearProgress} color='primary' />}
                </div>
                {decisions.map((decision, index) => (
                    <div key={decision.id || index} className={classes.row}>
                        {ENTRY_COLUMNS.map(column => (
                            <div
                                key={`${decision.id}-${column.field}`}
                                className={classes.column}
                                data-type={column.filterType}
                                style={
                                    column.filterType === 'date' || column.filterType === 'custom'
                                        ? { width: '300px', flex: '0 0 300px' }
                                        : { width: '200px', flex: '0 0 200px' }
                                }
                            >
                                <Entry>
                                    {column.field === 'situationCase' ? (
                                        (() => {
                                            const caseId = _.get(decision, '_decision.caseNameCardId', '');
                                            const caseName = CldFieldService.resolveCldField(cldFields, caseId);
                                            if (caseName && caseName !== 'None (Situation only)') {
                                                return caseName;
                                            }
                                            const situationId = _.get(decision, '_decision.situationNameCardId', '');
                                            const situationName = CldFieldService.resolveCldField(
                                                cldFields,
                                                situationId
                                            );
                                            if (situationName) {
                                                return situationName;
                                            }

                                            const _caseName = _.get(decision, 'Situation / Case Name', '');
                                            return _caseName || '-';
                                        })()
                                    ) : column.field === '_decision.documentSourceId' ? (
                                        CldFieldService.resolveCldField(cldFields, _.get(decision, column.field, ''))
                                    ) : column.filterType === 'date' ? (
                                        moment(_.get(decision, column.field)).format('DD.MMM.YYYY')
                                    ) : column.field === '_decision.title' ? (
                                        <Link to={`/decision/${decision.id}`} style={{ color: '#505050' }}>
                                            {_.get(decision, column.field, '-')}
                                        </Link>
                                    ) : (
                                        _.get(decision, column.field, '-')
                                    )}
                                </Entry>
                            </div>
                        ))}
                    </div>
                ))}
                {hasMore && (
                    <Button onClick={loadMoreTask.run} disabled={loadMoreTask.status === 'PROCESSING'} fullWidth>
                        {loadMoreTask.status === 'PROCESSING' ? (
                            <CircularProgress size={24} color='primary' />
                        ) : (
                            'Load more'
                        )}
                    </Button>
                )}
            </div>
            <Tooltip title='Clear all filters'>
                <IconButton className={classes.clearButton} onClick={clearFilter} aria-label='clear filters'>
                    <ClearIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={`Download filtered decisions (${total})`}>
                <Fab
                    className={`${isDownloading ? classes.stopButton : ''} ${classes.downloadFab}`}
                    onClick={
                        isDownloading
                            ? e => {
                                  e.stopPropagation();
                                  abortDownload();
                              }
                            : downloadDecisions
                    }
                    aria-label={`download ${total} decisions`}
                >
                    <div className={classes.downloadButtonContent}>
                        {isDownloading ? (
                            <>
                                <CircularProgress size={28} className={classes.buttonProgress} />
                                <StopIcon fontSize='small' className={classes.stopIcon} />
                            </>
                        ) : (
                            <>
                                <GetAppIcon />
                                {total > 0 && <div className={classes.badgeCount}>{total}</div>}
                            </>
                        )}
                    </div>
                </Fab>
            </Tooltip>
        </div>
    );
};

const mapStateToProps = (state: TState) => ({
    decisions: state.CldDashboard.decisions.decisions,
    filter: state.CldDashboard.decisions.filter || DEFAULT_DECISION_FILTER,
    pageNumber: state.CldDashboard.decisions.pageNumber,
    total: state.CldDashboard.decisions.total,
    loading: state.CldDashboard.decisions.loading || false,
    isDownloading: state.CldDashboard.decisions.isDownloading || false,
    cldFields: state.CldFields.cldFields,
    hasMore: state.CldDashboard.decisions.hasMore || false,
    facets: state.CldDashboard.decisions.facets || {
        confidentiality: [],
        _decision_languageId: [],
        _decision_typeOfDecisionId: [],
        _decision_documentSourceId: [],
        _decision_situationNameCardId: [],
        _decision_caseNameCardId: [],
        _decision_ApplicableAppeal: [],
        legalFindings: []
    }
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    fetchDecisions: () => dispatch(OCldDashboard.fetchDecisions()),
    setFilter: (filter: IDecisionFilter) => dispatch(OCldDashboard.setDecisionsFilter(filter)),
    clearFilter: () => dispatch(OCldDashboard.clearDecisionsFilter()),
    setPageNumber: (pageNumber: number) =>
        dispatch({ type: 'CLD_DASHBOARD_DECISIONS_SET_PAGE_NUMBER', data: pageNumber }),
    downloadDecisions: () => dispatch(OCldDashboard.downloadDecisions()),
    abortDownload: () => dispatch(OCldDashboard.abortDownloadDecisions()),
    fetchInitialFacets: () => dispatch(OCldDashboard.fetchInitialFacets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Decisions));
