import { TUTORIAL_FILM_URLS } from '../../../Resources/constants';

const tutorialFilmEn = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="training-and-contact/" >Training and contact</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:146/text [begin] -->
    <div id="c146" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">I-DOC</h1></div>
    <!--  Header: [end] -->
    <p class="bodytext">
    The Investigation Documentation
System (I-DOC) is a digital tool for
working on facts relevant to serious
violations of international criminal or
human rights law, including
documentation, investigation,
analyses, case preparation, trial and
later proceedings. Whereas the ICC
Legal Tools Database (LTD) and
Lexsitus provide users with access to
relevant legal sources and legal
information essential to understand
core international crimes, I-DOC is a
tool for practitioners to plan and
carry out documentation and
investigation work based on the
knowledge provided though the LTD
and Lexsitus. 

<br/><br/>

I-DOC offers capabilities to
systematise large quantities of
documentation and evidence,
criminal incidents and contextual
facts, suspects and institutions
involved, victims and witnesses,
protected property and objects, and
other relevant factual circumstances.
It helps to ensure the requisite
precision and quality throughout the
work processes.

<br/><br/>


The structure of the database provides effective overview of these
information-categories as well as the
possibility to map, search and
analyse different patterns and
linkages. Users can build criminal and
human rights cases based on these
information-categories, including
utilizing a matrix function for case
analysis based on the legal
requirements of the ICC Statue
crimes.

<br/><br/>

The concept of this tool has been
developed by Mr. Ilia Utmelidze,
drawing, inter alia, on the ICC Case
Matrix, ongoing brainstorming about
its conceptualisation, and
collaboration with a team of experts.
Mr. Utmelidze heads CILRAP’s CMN
department and can be reached at <a href="mailto:utmelidze@cilrap.org">utmelidze@cilrap.org</a>.
    </p>

    <div class="grid">
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-1/" target="_blank">
            <img src="/images/i-doc-1.png" style="width: 100%;" alt="How to use the I-Doc system" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-2/" target="_blank">
            <img src="/images/i-doc-2.png" style="width: 100%;" alt="How to build international criminal law cases in I-DOC" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/240424-idoc-3/" target="_blank">
            <img src="/images/i-doc-3.png" style="width: 100%;" alt="How to customize I-DOC" />
        </a>
        <a class="col-4" href="https://www.cilrap.org/cilrap-film/241202-i-doc" target="_blank">
            <img src="/images/i-doc-4.png" style="width: 100%;" alt="A film about the use of I-DOC" />
        </a>
    </div>

    </div>`;

export default tutorialFilmEn;
