import { STATUS_OF_WORK_CONTENT } from '../../../Resources/constants';

const workToolStatusRu = `	
<div id="content" class="content">
<div id="breadcrumbs">
<p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/">Статус работы над Инструментами</a>
</p>
</div>
<div id="getUrlText">
<div style="cursor:pointer;float:right;" title="Collapse tree" id="collapseTree"><i
        class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();"
    style="float:right;"><i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:138/header [begin] -->
<div id="c138" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
    <h1 class="csc-firstHeader">Статус работы над Инструментами</h1>
</div>
<!--  Header: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:138/header [end] -->

<!--  CONTENT ELEMENT, uid:137/html [begin] -->
<div id="c137" class="csc-default">
<!--  Raw HTML content: [begin] -->
<table class="public">
    <tbody>
        <tr>
            <td>Документы Международного уголовного суда</td>
            <td align="right">63,108</td>
        </tr>
        <tr>
            <td>Другие международные и гибридные уголовные юрисдикции</td>
            <td align="right">216,912</td>
        </tr>
        <tr>
            <td>Международные юридические документы</td>
            <td align="right">1,320</td>
        </tr>
        <tr>
            <td>Решения и документы в области прав человека</td>
            <td align="right">8,361</td>
        </tr>
        <tr>
            <td>Другие решения и документы международного права</td>
            <td align="right">10,341</td>
        </tr>
        <tr>
            <td>Национальные уголовные юрисдикции</td>
            <td align="right">20,448</td>
        </tr>
        <tr>
            <td>Публикации</td>
            <td align="right">4,059</td>
        </tr>
        <tr>
            <td>Комиссия ООН по военным преступлениям</td>
            <td align="right">11,809</td>
        </tr>
        <tr>
            <td>Международные и гибридные миссии по установлению фактов</td>
            <td align="right">2,008</td>
        </tr>
        <tr>
            <td></td>
            <td align="right"><strong>338,366</strong></td>
        </tr>
    </tbody>
</table>
<!--  Raw HTML content: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:137/html [end] -->

<!--  CONTENT ELEMENT, uid:50/text [begin] -->
<div id="c50" class="csc-default">
<!--  Text: [begin] -->
<p class="bodytext">(Числа на 28 ноябрь 2024 г.)</p>
<p class="bodytext">
    База данных Юридические инструменты МУС постоянно развивается в рамках проекта - в 
    нее ежедневно загружаются новые документы и поисковые данные. Прямой целью проекта 
    является включение в базу данных Юридические инструменты МУС каждого юридического документа,
     международного или национального, который может понадобиться практикующему специалисту, 
     работающему над основными международными преступлениями.
    </p>
<p class="bodytext">
    Метаданные (такие как название и дата документа, когда он был выпущен и кем) были зарегистрированы почти для всех документов в базе данных в соответствии с
    <a
        href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Legal_Tools_Metadata_Manual_version_1-1.pdf"
        target="_blank"> Руководством по метаданным</a>, используемым в рамках проекта Юридические инструменты МУС.
</p>
<p class="bodytext">
   МУС стремится к дальнейшему развитию содержания и технических качеств Юридических инструментов. Поскольку работа по сбору документов, регистрации метаданных для каждого документа и загрузке документов в базу данных Юридических инструментов МУС является трудоемкой, а МУС является действующим уголовным судом с ограниченными человеческими ресурсами, работа над Юридическими инструментами в значительной степени осуществляется внешними, по отношению к МУС, партерами и без каких-либо затрат для МУС. Внешние партнеры используют собственные человеческие ресурсы и сами находят финансирование для этой деятельности. Их вклад основан на официальных соглашениях о сотрудничестве с МУС. Правительства Австрии, Германии, Нидерландов, Норвегии и Швейцарии внесли свой вклад в деятельность внешних партнеров Юридических инструментов.  
    <a
        href="status-of-work-on-the-tools/table-of-responsibilities/">Здесь</a> вы видите таблицу, показывающую распределение работы между внешними партнерами.
    </p>
<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:50/text [end] -->
<video controls poster="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.thumbnailUrl}" width="100%">
    <source src="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.url}" style="width: 100%;" type="video/mp4">
</video>
</div>

`;
export default workToolStatusRu;
