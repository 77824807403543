import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Paper,
    Typography,
    Checkbox,
    Button,
    Tooltip,
    Theme as ITheme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { Link } from 'react-router-dom';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../Resources/Theme';
import { OApp, TToastVariant, TAppState } from '../../RootReducer/AppReducer';
import utilities from '../../Resources/Utils';
import { HISTORY } from '../../screens/RootLayout';
import { TState } from '../../RootReducer/index';
import classNames from 'classnames';
import { APPEAL_CONFIG, getDownloadUrl, OCLDDocuments } from '../../Features/CLDDocuments/redux-config';
import Bookmark from '../../Components/Bookmark';
import { TUser, ICldPermissions } from '../../Features/Authentication/@types';
import { PERMANENT_URL } from './CLDMetadata';
import { InputProgress } from '../../Components/CLDList/ListItem';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { TOOLTIPS } from '../../Features/CLDDocuments/tooltips';
import { getListItemActionLabelTranslation } from '../../Features/CLDDocuments/utils';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { CARD_PANEL_LINK_COPIED_TO_CLIPBOARD } from '../../Features/Translations/translationKeys.ltd';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps, WithTranslationProps {
    item: TCLDDocument;
    hideCheckbox?: boolean;
}

interface IStateProps {
    user: TUser;
    checkedIds: Array<string>;
    cldPermissions: ICldPermissions;
}

interface IDispatchProps {
    openDocument: Function;
    showToast: Function;
    deleteLegalFinding: (id) => void;
    confirmationDialog: (message: string, onConfirm: () => void, onDelete: () => void) => void;
    toggleCheck: (id: string) => void;
    newLegalFindingCopy: (id: string) => void;
}

// const labels = [
//     { name: 'Date created', key: "Date created" },
//     { name: 'Language(s)', key: 'metadata_language_s' },
//     { name: 'External identifier', key: 'metadata_source' },
//     { name: 'Persistent URL', key: 'metadata_persistent_url' },
// ]

const labels = ['Date created', 'Language(s)', 'Source', 'Persistent URL'];

type TAction = 'COPY URL' | 'SEE FULL' | 'DOWNLOAD' | 'READ LATER' | 'METADATA';

const actions: Array<TAction> = ['COPY URL', 'SEE FULL', 'DOWNLOAD', 'READ LATER'];

class LegalFindingItem extends Component<Props & IStateProps> {
    openDocument = () => {
        this.props.openDocument(_.get(this.props.item, 'id'));
    };

    handleDelete = () => {
        const { item } = this.props;
        if (_.isEmpty(item)) return;
        this.props.confirmationDialog(
            'Delete legal finding?',
            () => this.props.deleteLegalFinding(item.id),
            () => null
        );
        // this.props.deleteLegalFinding(item.hit.id);
    };

    actionClicked = (action: TAction) => {
        const { item, getTranslation } = this.props;
        const document = item;

        switch (action) {
            case 'COPY URL': {
                const id = document.id;
                // if (!document.ltdDocInserted)
                //     return;
                // utilities.copyLink(document.ltdDoc.pdfURL)
                const host = window.location.host;
                utilities.copyLink(`${host}/clddoc/${id}/`);
                this.props.showToast(
                    getTranslation(CARD_PANEL_LINK_COPIED_TO_CLIPBOARD) || 'Link copied to clipboard',
                    'success'
                );
                break;
            }
            case 'DOWNLOAD': {
                const url = getDownloadUrl(document.id);
                window.open(url, '_blank');
                break;
            }
            case 'READ LATER': {
                break;
            }
            case 'SEE FULL': {
                const id = document.id;
                this.props.openDocument(id);
                HISTORY.push(`/clddoc/${id}/`);
                break;
            }
            case 'METADATA': {
                const id = document.id;
                this.props.openDocument(id);
                HISTORY.push(`/clddoc/${id}/metadata`);
                break;
            }
            default:
                return;
        }
    };

    handleCopyLegalFinding = () => {
        const { item } = this.props;
        this.props.newLegalFindingCopy(item.id);
    };

    getTranslatedLabel = (action: TAction) => {
        const { getTranslation } = this.props;
        return getListItemActionLabelTranslation(action, getTranslation);
    };

    render() {
        const { classes, item, user, checkedIds = [], cldPermissions } = this.props;
        const document = item;
        const hasNoLegalFinding = _.isEmpty(_.trim(document.legalFindingText));
        const legalFindingtext = document.legalFinding;

        const META = [
            {
                label: 'Finding',
                value: (document.Finding || '').toString(),
                tooltip: TOOLTIPS['Finding'],
            },
            // {
            //     label: 'Document number',
            //     value: document['Document Number'],
            //     tooltip: 'The unique identifier of the decision in the format given by the ICC'
            // },
            ..._.map(_.take(document.Keyword, 4), (keyword, i) => ({
                label: `Keyword${i + 1}`,
                value:
                    `<span>${keyword}</span>` +
                    (i === 3 && document.Keyword && document.Keyword.length > 4
                        ? `<span class="extra"> +${document.Keyword.length - 4} keywords</span>`
                        : ''),
                tooltip: TOOLTIPS['Keyword'],
            })),

            {
                label: 'Permanent URL',
                tooltip: '',
                value: !_.isEmpty(document.id) ? PERMANENT_URL + document.id : '',
            },
        ].filter(x => !_.isUndefined(x));

        if (_.isEmpty(item)) return <div />;

        // const isAdmin = getCLDUserRole(user.roles) === 'ADMIN';

        return (
            <Paper elevation={0} className={classes.root}>
                <div className={classes.tagSection}>
                    <div className={classes.documentId}>
                        {!this.props.hideCheckbox && (
                            <Checkbox
                                color='primary'
                                checked={checkedIds.indexOf(document.id) > -1}
                                onChange={() => this.props.toggleCheck(document.id)}
                            />
                        )}

                        <Tooltip title={TOOLTIPS['ID']}>
                            <Typography className='documentNumber' variant='caption'>
                                {'Legal finding ' + document.ID}
                            </Typography>
                        </Tooltip>
                    </div>
                    <div className='flex-row align-center'>
                        {// isAdmin ? <Typography variant="caption" color={
                        //     _.get(document, 'progress') === 'Finalised' ? "primary" : "textPrimary"
                        // } className="mx-2">{document.progress ? document.progress : ''}</Typography> : null
                        cldPermissions.statusVisibility ? <InputProgress document={document} /> : null}
                        <Tag document={document} />
                    </div>
                </div>

                <div className={classNames(classes.leading, 'my-1')}>
                    <Link onClick={this.openDocument} className={classes.link} to={`/clddoc/${document.id}/`}>
                        <Typography
                            color='inherit'
                            className={classes.title}
                            dangerouslySetInnerHTML={{
                                __html: !hasNoLegalFinding
                                    ? _.truncate(legalFindingtext, { length: 400 })
                                    : 'No legal findings contained in this decision',
                            }}
                        >
                            {/* {_.truncate(document.legalFinding, { length: 380 })} */}
                        </Typography>
                    </Link>
                </div>

                <div className={classes.metadataContainer}>
                    {_.map(META, (meta, i) =>
                        meta ? (
                            <Tooltip key={i} title={meta.tooltip ? meta.tooltip : ''}>
                                <div className={classes.metaItem} key={i}>
                                    <Typography color='primary' style={{ flex: 1, textAlign: 'right' }}>
                                        {meta.label}:
                                    </Typography>
                                    {meta.label === 'Permanent URL' ? (
                                        <a
                                            style={{ color: Theme.Colors.Primary.light, flex: 3, marginLeft: 20 }}
                                            href={meta.value}
                                            target='_blank'
                                        >
                                            <Typography color='inherit'>{meta.value}</Typography>
                                        </a>
                                    ) : (
                                        <Typography
                                            className={classes.metaItemAlt}
                                            dangerouslySetInnerHTML={{ __html: meta.value }}
                                        ></Typography>
                                    )}
                                </div>
                            </Tooltip>
                        ) : null
                    )}
                </div>

                <div className={classes.footerContainer}>
                    {document.Importance01 ? (
                        <Typography style={{ color: Theme.Colors.Fourth }}>
                            {document.Importance01} importance
                        </Typography>
                    ) : (
                        <div>&nbsp;</div>
                    )}

                    <div className={classes.actionContainer}>
                        {_.map(actions, action => {
                            if (action === 'READ LATER')
                                return (
                                    <Bookmark
                                        type='cld'
                                        docType='CldDoc'
                                        classes={{ actionBtn: classes.actionBtn }}
                                        color='primary'
                                        key={_.uniqueId('bookmark')}
                                        variant='caption'
                                        id={document.id}
                                    />
                                );
                            else if (action === 'COPY URL' || action === 'DOWNLOAD')
                                return (
                                    <Button
                                        variant='text'
                                        color='primary'
                                        className={classes.actionBtn}
                                        key={_.uniqueId('btn')}
                                        onClick={e => this.actionClicked(action)}
                                    >
                                        {this.getTranslatedLabel(action)}
                                    </Button>
                                );
                            else
                                return (
                                    <Button
                                        className={classes.actionBtn}
                                        variant='text'
                                        color='primary'
                                        key={_.uniqueId('btn')}
                                        onClick={e => this.actionClicked(action)}
                                    >
                                        {this.getTranslatedLabel(action)}
                                    </Button>
                                );
                        })}
                        {cldPermissions.docEditFn(document) && (
                            <Tooltip title='Create new Legal finding from this document'>
                                <Button onClick={this.handleCopyLegalFinding} className={classes.deleteButton}>
                                    <i className='material-icons'>library_add</i>
                                </Button>
                            </Tooltip>
                        )}
                        {cldPermissions.docDeleteFn(document) && !document.deleted ? (
                            <Button className={classes.deleteButton} onClick={this.handleDelete}>
                                <i className='material-icons'>delete_outline</i>
                            </Button>
                        ) : null}
                    </div>
                </div>
            </Paper>
        );
    }
}

const _Tag = ({ classes, document = {}, type = 'tag' }) => {
    const appeal = _.get(document, 'Appeal[0]');
    if (!appeal) return <div />;

    const { AppealType } = appeal;
    const tag = _.find(APPEAL_CONFIG, config => config.value === AppealType);
    if (!tag) return <div />;

    if (type === 'tag')
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='caption' style={{ color: Theme.Colors.TextLight, fontSize: 10 }}>
                    {tag.text}
                </Typography>
                <div
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        marginLeft: 10,
                        background: tag.color,
                    }}
                />
            </div>
        );
    else
        return (
            <Typography
                component='span'
                variant='caption'
                style={{
                    color: 'white',
                    lineHeight: 1,
                    padding: 2,
                    margin: '0px 2px',
                    background: tag.color,
                    display: 'inline-block',
                }}
            >
                {tag.text}
            </Typography>
        );
};

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Auth, ['user']),
    checkedIds: _.get(state.CLDDocuments, 'selected'),
    cldPermissions: _.get(state.Auth, 'cldPermissions'),
});

const mapDispatchToProps = dispatch => ({
    openDocument: id => dispatch(ODetail.openDocument(id, 'CLD', false)),
    showToast: (message, variant: TToastVariant) => dispatch(OApp.showToast(message, variant)),
    deleteLegalFinding: (id: string) => dispatch(OCLDDocuments.deleteLegalFinding(id)),
    confirmationDialog: (message: string, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
    toggleCheck: (id: string) => dispatch(OCLDDocuments.toggleSelect(id)),
    newLegalFindingCopy: (id: string) => dispatch(ONewLegalFinding.copyLegalFinding(id)),
});

const STYLES = (theme: ITheme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'relative',
            padding: '10px 32px 0px 32px',
            margin: '5px 0px',
            transition: '200ms linear',
            // '&:hover': {
            //     boxShadow: `0px 1px 3px ${fade(Theme.Colors.Fourth, 0.3)}`
            // }
        },
        tagSection: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            top: -8,
            justifyContent: 'space-between',
        },
        documentId: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            left: 0,
            '& span': { padding: 0 },
            '& .documentNumber': {
                background: Theme.Colors.Fourth,
                color: 'white',
                padding: '0px 2px',
            },
        },
        primary: {
            color: Theme.Colors.Secondary.main,
        },
        extraPreview: {},
        metadataContainer: {
            padding: '16px 0px',
            borderBottom: `1px dashed ${fade(theme.palette.text.secondary, 0.3)}`,
        },
        metaItem: {
            position: 'relative',
            left: -22,
            display: 'flex',
            alignItems: 'flex-start',
            margin: '5px 0px',
            '& p': {
                fontSize: 12,
                fontWeight: 500,
            },
            '& a': {
                textDecoration: 'none',
            },
        },
        metaItemAlt: {
            flex: 3,
            marginLeft: 20,
            display: 'flex',
            flexDirection: 'column',
            '& .extra': {
                textAlign: 'end',
            },
        },
        listItem: {
            display: 'flex',
            '& p': {
                fontSize: 12,
            },
        },
        title: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.4,
            color: Theme.Colors.Third,
        },
        leading: {
            display: 'flex',
            alignItems: 'center',
            '& > span': {
                padding: 0,
                marginRight: 4,
            },
        },
        label: {
            fontWeight: 500,
        },
        link: {
            textDecoration: 'none',
        },
        previewContainer: {
            // paddingBottom: 16,
            margin: '4px 0px',
            '& span': {
                background: Theme.Colors.Highlight,
            },
            '& > div': {
                fontSize: 14,
                fontWeight: 500,
                margin: '4px 0px',
            },
        },
        actionBtn: {
            color: Theme.Colors.Third,
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',
            '& button': {
                fontSize: 12,
                transition: '300ms linear',
                borderRadius: 0,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 10,
                },
                '&:hover': {
                    background: 'white',
                    boxShadow: `0px 1px 3px ${fade(Theme.Colors.Grayscale.Black, 0.2)}`,
                },
                '& span': {
                    fontWeight: 500,
                    cursor: 'pointer',
                    transition: '500ms linear',
                    // color: Theme.Colors.Third,
                    '&:hover': {
                        // color: Theme.Colors.Third
                    },
                },
            },
        },
        deleteButton: {
            position: 'relative',
            // right: 18,
            // top: -14,
            background: 'white',
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            // border: `1px solid ${Theme.Colors.Error}`,
            '& i': {
                // fontSize: 14,
                color: Theme.Colors.Third,
            },
        },
        footerContainer: {
            position: 'relative',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                '& > p': {
                    marginTop: 24,
                    position: 'absolute',
                    top: -44,
                    fontSize: 10,
                },
                '& button:first-child': {
                    padding: 0,
                },
            },
        },
        previewText: {
            transition: '200ms linear',
            '&>div, &>p': {
                fontWeight: 400,
                lineHeight: 1.2,
                display: 'inline',
            },
            '&:hover': {
                background: fade(Theme.Colors.Fourth, 0.1),
            },
            '& em': {
                background: Theme.Colors.Highlight,
            },
        },
    });

export const Tag = withStyles(STYLES)(_Tag);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(LegalFindingItem)));
