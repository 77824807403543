export const cldUrls = ['/cld.*', '/decision.*', '/clddoc.*', '/cld-dashboard.*', '/cld-help.*'];
export const sccUrls = ['/scc.*', '/sccdoc.*', '/scc-dashboard.*', '/scc-help.*'];

export const isCld = (pathname: string) => cldUrls.some(url => pathname.match(url));
export const isScc = (pathname: string) => sccUrls.some(url => pathname.match(url));

export const PRIVACY_POLICY_EN = `
By accessing this site, certain information about the User, such as Internet protocol (IP) 
addresses, navigation through the Site, the software used and the time spent, along with 
other similar information, will be stored on International Criminal Court servers. These 
will not specifically identify the User. The information will be used internally only for 
web site traffic analysis. If the User provides unique identifying information, such as 
name, address and other information on forms stored on this Site, such information will 
only be used to support the ICC Legal Tools Database (‘LTD’) account that a User 
registers or to improve the LTD, and will not be published for general access. The 
International Criminal Court, however, assumes no responsibility for the security of this 
information.
`;

export const PRIVACY_POLICY_FR = `
Lorsqu’un utilisateur se connecte sur ce site, certaines informations 
le concernant, tels que ses adresses de protocole internet (IP), la 
navigation sur le site, les logiciels utilisés, le temps passé et d’autres
données similaires, sont stockés sur les serveurs de la Cour pénale internationale.
Ces informations ne permettent pas d'identifier spécifiquement l'utilisateur.
Elles seront utilisées en interne pour analyser la fréquentation du site.
Si l’utilisateur fournit des renseignements personnels, tels que son nom, 
son adresse et d'autres données sur les formulaires stockés sur le site, 
ceux-ci ne seront utilisés que pour gérer le compte de l'intéressé sur le 
site ou apporter des améliorations à la base de données des Outils juridiques.
Ces renseignements ne seront pas publiés pour un usage général. La Cour pénale
internationale n’assume toutefois aucune responsabilité quant à la sécurité des
renseignements en question.
`;

export const PRIVACY_POLICY_ES = `
Al acceder a este sitio, determinadas informaciones relativas al Usuario, 
en particular las relativas al protocolo Internet (IP), direcciones, navegación dentro 
del Sitio, programas informáticos utilizados o tiempo de uso del Sitio, junto con 
otras informaciones similares, se almacenarán en los servidores de la Corte Penal 
Internacional. Estos no identificarán al Usuario específicamente. Se hará uso 
interno de la información únicamente para fines de análisis de la frecuentación del
sitio. Si el Usuario aporta información específica de identificación, por ejemplo 
nombre, dirección u otra información, en formularios almacenados en este Sitio, esa 
información solamente se utilizará para dar apoyo   a la cuenta de la Base de Datos
de Herramientas Jurídicas ('LTD') en la que se registra un Usuario o para mejorar la 
LTD, y no se publicará de manera que el público general tenga acceso a ella. No obstante,
la Corte Penal Internacional  no asume responsabilidad alguna en relación con la seguridad
de esta información.
`;

export const PRIVACY_POLICY_RU = `
При доступе к этому сайту определенная информация о Пользователе, такая как 
интернет-протокол (IP) адреса, навигация по Сайту, используемое программное
обеспечение и затраченное время, а также другая аналогичная информация будет 
сохранена на серверах Международного уголовного суда. Эти данные не будут конкретно
идентифицировать Пользователя. Информация будет использоваться только для внутреннего 
анализа посещаемости веб-сайта. Если Пользователь предоставляет дополнительную 
идентифицирующую информацию, такую как имя, адрес и другую информацию в формах,
хранящихся на этом Сайте, такая информация будет использоваться только для поддержки
учетной записи базы данных Юридические инструменты МУС («LTD»), зарегистрированной
Пользователем, или для улучшения LTD, и не будет публиковаться для общего доступа. 
Однако Международный уголовный суд не берет на себя ответственность за безопасность 
этой информации.
`;

export const PRIVACY_POLICY_ZH = `
用户在使用网站时，其特定信息，如IP地址、使用网站的路径、使用的软件、
使用的时长及其它类似信息会存储到国际刑事法院的服务器上。这些信息不会特别识别用户身份。
上述信息仅用于内部关于网站流量的分析。若用户提供了披露身份的特定信息，如姓名、
地址和存储在网站表格里的其他信息，这些信息只会被用来支持用户创建的法律工具数据库账号或者改进数据库的功能，
而不会被公开。但是国际刑事法院不对这些信息的安全负责。
`;

export const TUTORIAL_FILM_LTD_URLS = [
    'https://www.cilrap.org/cilrap-film/220425-ltd-1/',
    'https://www.cilrap.org/cilrap-film/220429-ltd-2/',
    'https://www.cilrap.org/cilrap-film/220426-ltd-3/',
    'https://www.cilrap.org/cilrap-film/220425-ltd-4/',
    'https://www.cilrap.org/cilrap-film/220429-ltd-5/',
    'https://www.cilrap.org/cilrap-film/220429-ltd-6/',
    'https://www.cilrap.org/cilrap-film/220430-ltd-7/',
    'https://www.cilrap.org/cilrap-film/220429-ltd-8/',
    'https://www.cilrap.org/cilrap-film/220429-ltd-9/',
    'https://www.cilrap.org/cilrap-film/220430-ltd-10/',
    'https://www.cilrap.org/cilrap-film/220430-ltd-11/',
    'https://www.cilrap.org/cilrap-film/220430-ltd-12/',
    'https://www.cilrap.org/cilrap-film/220430-ltd-13/',
    'https://www.cilrap.org/cilrap-film/220430-ltd-14/',
    'https://www.cilrap.org/cilrap-film/230418-ltd-15/',
    'https://www.cilrap.org/cilrap-film/230418-ltd-16/',
    'https://www.cilrap.org/cilrap-film/230418-ltd-17/',
    'https://www.cilrap.org/cilrap-film/230418-ltd-18/',
    'https://www.cilrap.org/cilrap-film/240910-ltd-19',
];
export const TUTORIAL_FILM_LEXSITUS_URLS = [
    'https://www.cilrap.org/cilrap-film/210620-lexsitus-lectures/',
    'https://www.cilrap.org/cilrap-film/230207-lexsitus-clicc/',
    'https://www.cilrap.org/cilrap-film/230207-lexsitus-ed/',
    'https://www.cilrap.org/cilrap-film/230207-lexsitus-mpd/',
    'https://www.cilrap.org/cilrap-film/230207-lexsitus-preparatory-works/',
];
export const TUTORIAL_FILM_URLS = [...TUTORIAL_FILM_LTD_URLS, ...TUTORIAL_FILM_LEXSITUS_URLS];

export const STATUS_OF_WORK_CONTENT = {
    TUTORIAL_FILMS: {
        thumbnailUrl: '/images/status-of-work/remarks-on-recent-ltd-development.jpg',
        url: 'https://cilrap-film.fra1.digitaloceanspaces.com/241202-ltd-lexsitus.mp4',
        title: 'Remarks on recent LTD and Lexsitus developments',
    },
};
