import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    AppBar,
    Typography,
    Tab,
    Tabs,
    Button,
    MenuItem,
    Menu,
    Popover,
    Tooltip,
    Theme as MuiTheme,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Theme from '../../Resources/Theme';
import classNames from 'classnames';
import UserControls from './UserControls';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { HOME_URL } from '../../Features/LTDDocuments/redux-config';
import { LocationDescriptor } from 'history';
import { TUser, ILtdPermissions, ICldPermissions } from '../../Features/Authentication/@types';
import ICCLogo from '../../assets/icc-logo-white.png';
import SCCLogo from '../../assets/icc-logo-white-scc.png';
import { DASHBOARD_ROUTES } from '../../screens/LTDDashboard';
import { getUserRole } from '../../Features/Authentication/redux-config';
import { OCLDDocuments } from '../../Features/CLDDocuments/redux-config';
import { HISTORY } from '../../screens/RootLayout';
import CldMenu from './CldMenu';
import LtdReportIssue from './LtdReportIssue';
import { CLD_DASHBOARD_ROUTES } from '../../screens/CLDDashboard';
import LocaleSwitcher from '../../Features/Translations/LocaleSwitcher';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import {
    GENERAL_READING_LIST,
    MENU_LIST_BACKGROUND,
    MENU_LIST_BOOKONTHE,
    MENU_LIST_COMMENTARY,
    MENU_LIST_CONTACT_US,
    MENU_LIST_COPPERATION,
    MENU_LIST_HOWTOUSE_PURL,
    MENU_LIST_ICC_MAIN_MENU,
    MENU_LIST_IDOC,
    MENU_LIST_LEGAL_CORDANATION,
    MENU_LIST_LEGAL_TOOLS,
    MENU_LIST_LEXSISTUS,
    MENU_LIST_LTAC,
    MENU_LIST_MENU,
    MENU_LIST_NATIONAL,
    MENU_LIST_OPENACCESS,
    MENU_LIST_PERSISTENT,
    MENU_LIST_RESPONSIBILITY,
    MENU_LIST_SCC,
    MENU_LIST_STATUS_WORK,
    MENU_LIST_THE_PUBILC,
    MENU_LIST_TUTORIAL_FILMS,
    SCC_TITLE,
} from '../../Features/Translations/translationKeys.ltd';
import { SCC_DASHBOARD_ROUTES } from '../../screens/SCCDashboard';

interface Props extends WithStyles<typeof STYLES>, RouteComponentProps<any> {
    // tabs: Array<any>
    // alt?: boolean
}

interface IStateProps {
    user: TUser;
    ltdPermissions: ILtdPermissions;
    cldPermissions: ICldPermissions;
}

interface IDispatchProps {
    cldResetFilters: () => void;
}

const TABS: Array<{
    name: string;
    url: string;
    fullName: string;
    disabled: boolean;
    isVisible?: boolean;
}> = [
    {
        name: 'LTD',
        url: '/',
        fullName: 'ICC Legal Tools Database',
        disabled: false,
        isVisible: true,
    },
    {
        name: 'CLD',
        url: '/cld',
        fullName: 'ICC Case Law Database',
        disabled: false,
        isVisible: true,
    },
    {
        name: 'SCC',
        url: '/scc',
        fullName: 'Special Criminal Court (CAR) Database',
        disabled: false,
        isVisible: false,
    },
];

const PathId = {
    LTD: TABS.findIndex(tabItem => tabItem.name === 'LTD'),
    CLD: TABS.findIndex(tabItem => tabItem.name === 'CLD'),
    SCC: TABS.findIndex(tabItem => tabItem.name === 'SCC'),
    decision: TABS.findIndex(tabItem => tabItem.name === 'CLD'),
    readinglist: TABS.length,
    rest: TABS.length + 1,
};

class AppHeader extends Component<Props & IStateProps & IDispatchProps & WithTranslationProps> {
    state = {
        activeTab: 0,
    };

    componentDidMount() {
        this.handleTabState();
    }

    componentDidUpdate() {
        this.handleTabState();
    }

    handleTabState = () => {
        const curUrl = window.location.pathname;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        let isLTDDashboard = false;
        let activeTab = PathId.LTD;
        // if (/\/.*/i.test(location))
        if (/\/cld.*/i.test(location)) activeTab = PathId.CLD;
        if (/\/scc.*/i.test(location)) activeTab = PathId.SCC;
        else if (/\/decision.*/i.test(location)) activeTab = PathId.decision;
        else if (/\/readinglist.*/i.test(location)) activeTab = PathId.readinglist;
        else if (
            /cld\/privacy.*/i.test(location) ||
            /cld\/terms.*/i.test(location) ||
            /\/reset-password.*/i.test(location)
        )
            activeTab = PathId.rest;

        if (this.state.activeTab !== activeTab) {
            this.setState({ activeTab });
        }
    };

    navigateBack = () => {
        const curUrl = window.location.pathname;
        HISTORY.goBack();
        return;

        if (/readinglist\/.*/.test(curUrl)) {
            if (HISTORY) HISTORY.push('/readinglist');
            else this.props.history.push('/readinglist');
        } else {
            if (HISTORY) HISTORY.goBack();
            else this.props.history.goBack();
            // if (HISTORY)
            //     HISTORY.push(HOME_URL().path);
            // else
            //     this.props.history.push(HOME_URL().path);
        }
    };

    onLogoClicked = (tab: { name: string; url: string; fullName: string; disabled: boolean }) => {
        if (tab.name === 'CLD') this.props.cldResetFilters();
    };

    getAppbarBackground = (activeTab: number, dashBoardVariant: 'Ltd' | 'Scc' | 'Cld') => {
        if (dashBoardVariant === 'Ltd') return Theme.Colors.Grayscale.Grey7;
        if (dashBoardVariant === 'Scc' || activeTab === PathId.SCC) return Theme.Colors.Grayscale.White;
        return activeTab && activeTab !== PathId.rest && activeTab !== PathId.readinglist
            ? Theme.Colors.Primary.main
            : Theme.Colors.Secondary.main;
    };

    getDashboardHeader = () => {
        const { activeTab } = this.state;
        const { classes } = this.props;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        const shouldShowDashboardTab = true;
        let isLTDDashboard = false;
        let isSCCDashboard = false;
        if (/\/ltd-dashboard.*/i.test(location)) isLTDDashboard = true;
        if (/\/scc-dashboard.*/i.test(location)) isSCCDashboard = true;

        return (
            <>
                <Link
                    onClick={e => this.onLogoClicked(TABS[activeTab])}
                    to={_.get(TABS[activeTab], 'url')}
                    style={{ flex: 0 }}
                    className={classNames(classes.logo, classes.tab, isSCCDashboard ? classes.sccTab : '')}
                >
                    <img src={isSCCDashboard ? SCCLogo : ICCLogo} width={50} className='mx-2' height={40} alt='logo' />
                </Link>
                {isLTDDashboard ? (
                    <LTDDashboardTabs visible={shouldShowDashboardTab} ltdPermissions={this.props.ltdPermissions} />
                ) : isSCCDashboard ? (
                    <SCCDashboardTabs visible={shouldShowDashboardTab} />
                ) : (
                    <CLDDashboardTabs visible={shouldShowDashboardTab} cldPermissions={this.props.cldPermissions} />
                )}
            </>
        );
    };

    render() {
        const { classes, user = {}, getTranslation } = this.props;
        const { activeTab } = this.state;
        // const curUrl = window.location.pathname;
        // console.log("test url ", /readinglist\/.*/.test(curUrl))

        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        let isLTDDashboard = false;
        let isCLDDDashboard = false;
        let isSCCDashboard = false;
        // let activeTab = 0;
        // // if (/\/.*/i.test(location))
        // activeTab = 0;
        // if (/\/cld.*/i.test(location))
        //     activeTab = 1
        // else if (/\/decision.*/i.test(location))
        //     activeTab = 1
        // else if (/\/readinglist.*/i.test(location))
        //     activeTab = 2
        // else if (/cld\/privacy.*/i.test(location) || /cld\/terms.*/i.test(location) || /\/reset-password.*/i.test(location))
        //     activeTab = 3

        if (/\/ltd-dashboard.*/i.test(location)) isLTDDashboard = true;
        if (/\/cld-dashboard.*/i.test(location)) isCLDDDashboard = true;
        if (/\/scc-dashboard.*/i.test(location)) isSCCDashboard = true;

        const shouldShowDashboardTab = true;
        return (
            <AppBar
                elevation={1}
                style={{
                    backgroundColor: this.getAppbarBackground(
                        activeTab,
                        isLTDDashboard ? 'Ltd' : isSCCDashboard ? 'Scc' : 'Cld'
                    ),
                }}
                className={classes.root}
                position='static'
            >
                <div
                    className='flex-row align-center'
                    style={{
                        flex: activeTab === PathId.readinglist || isLTDDashboard || isSCCDashboard ? 'unset' : 1,
                    }}
                >
                    {activeTab === PathId.readinglist ? (
                        <>
                            <Link
                                to={'/'}
                                onClick={e => this.onLogoClicked(TABS[1])}
                                className={classNames(classes.logo, classes.tab, isSCCDashboard ? classes.sccTab : '')}
                            >
                                <img
                                    src={activeTab === PathId.SCC ? SCCLogo : ICCLogo}
                                    width={50}
                                    className='mx-2'
                                    height={40}
                                    alt='logo'
                                />
                            </Link>
                            <Button
                                onClick={this.navigateBack}
                                className={classNames(classes.logo, classes.tab, isSCCDashboard ? classes.sccTab : '')}
                            >
                                <Typography>
                                    <i className='material-icons' style={{ fontSize: 34 }}>
                                        keyboard_arrow_left
                                    </i>
                                </Typography>
                            </Button>
                        </>
                    ) : null}
                    {activeTab === PathId.rest ? (
                        <Link
                            to={'/'}
                            className={classNames(classes.logo, classes.tab, isSCCDashboard ? classes.sccTab : '')}
                        >
                            <img
                                src={activeTab === PathId.SCC ? SCCLogo : ICCLogo}
                                width={50}
                                className='mx-2'
                                height={40}
                                alt='logo'
                            />
                            <Typography>ICC Legal Tools Database</Typography> : null
                        </Link>
                    ) : null}
                    {activeTab !== PathId.readinglist && activeTab !== PathId.rest ? (
                        !isLTDDashboard && !isCLDDDashboard && !isSCCDashboard ? (
                            <Link
                                onClick={e => this.onLogoClicked(TABS[activeTab])}
                                to={_.get(TABS[activeTab], 'url')}
                                className={classNames(
                                    classes.logo,
                                    classes.tab,
                                    isSCCDashboard || activeTab === PathId.SCC ? classes.sccTab : ''
                                )}
                            >
                                <img
                                    src={activeTab === PathId.SCC ? SCCLogo : ICCLogo}
                                    width={50}
                                    className='mx-2'
                                    height={40}
                                    alt='logo'
                                />
                                <Typography className={activeTab === 1 ? classes.logoTitleCLD : classes.logoTitleLTD}>
                                    {_.get(TABS[activeTab], 'name') === 'SCC'
                                        ? getTranslation(SCC_TITLE)
                                        : _.get(TABS[activeTab], 'fullName') || 'LTD'}
                                </Typography>
                            </Link>
                        ) : (
                            this.getDashboardHeader()
                        )
                    ) : null}
                </div>

                {!isLTDDashboard && !isCLDDDashboard && !isSCCDashboard ? (
                    activeTab === PathId.LTD || activeTab === PathId.CLD || activeTab === PathId.SCC ? (
                        <Tabs
                            centered
                            value={activeTab}
                            indicatorColor='secondary'
                            classes={{
                                root: classNames(
                                    classes.tab,
                                    isSCCDashboard || activeTab === PathId.SCC ? classes.sccTab : '',
                                    classes.mainTab,
                                    classes.rootLTDCLDTab,
                                    'print-hidden'
                                ),
                                indicator: activeTab === PathId.SCC ? classes.sccTabIndicator : classes.tabIndicator,
                                flexContainer: classes.rootTab,
                            }}
                        >
                            {_.map(
                                TABS.filter(t => t.isVisible),
                                (tab, i) =>
                                    tab.disabled ? (
                                        <Link
                                            className={
                                                activeTab === PathId.SCC
                                                    ? i === activeTab
                                                        ? classes.sccTabSelected
                                                        : classes.sccTabInactive
                                                    : i === activeTab
                                                    ? classes.tabSelected
                                                    : classes.tabInactive
                                            }
                                            key={_.uniqueId(tab.name)}
                                            to={tab.url}
                                        >
                                            <Tab
                                                classes={{
                                                    labelContainer: classes.tabs,
                                                    selected:
                                                        activeTab === PathId.SCC
                                                            ? classes.sccTabSelected
                                                            : classes.tabSelected,
                                                }}
                                                label={tab.name}
                                            />
                                        </Link>
                                    ) : (
                                        <Link
                                            className={
                                                activeTab === PathId.SCC
                                                    ? i === activeTab
                                                        ? classes.sccTabSelected
                                                        : classes.sccTabInactive
                                                    : i === activeTab
                                                    ? classes.tabSelected
                                                    : classes.tabInactive
                                            }
                                            key={_.uniqueId(tab.name)}
                                            to={tab.url}
                                        >
                                            <Tab
                                                classes={{
                                                    labelContainer: classes.tabs,
                                                    selected:
                                                        activeTab === PathId.SCC
                                                            ? classes.sccTabSelected
                                                            : classes.tabSelected,
                                                }}
                                                label={tab.name}
                                            />
                                        </Link>
                                    )
                            )}
                        </Tabs>
                    ) : (
                        activeTab === PathId.readinglist && (
                            <div
                                className={classNames(classes.logo, classes.tab, isSCCDashboard ? classes.sccTab : '')}
                            >
                                <Typography className={classes.titleText}>
                                    {getTranslation(GENERAL_READING_LIST) || 'Reading list'}
                                </Typography>
                            </div>
                        )
                    )
                ) : null
                // <LTDDashboardTabs visible={shouldShowDashboardTab} ltdPermissions={this.props.ltdPermissions} />
                }

                <div
                    className={classNames(
                        classes.tab,
                        isSCCDashboard || activeTab === PathId.SCC ? classes.sccTab : '',
                        'print-hidden'
                    )}
                    style={{ justifyContent: 'flex-end' }}
                >
                    <div
                        className={classNames(
                            classes.largeDeviceOnly,
                            'only-desktop',
                            'relative',
                            classes.userControlContainer
                        )}
                    >
                        <UserControls
                            user={user}
                            variant={activeTab === PathId.CLD ? 'cld' : activeTab === PathId.SCC ? 'scc' : 'ltd'}
                        />
                    </div>

                    {!activeTab ? (
                        <div className={classes.largeDeviceOnly}>
                            <LtdReportIssue />
                        </div>
                    ) : null}
                    <LocaleSwitcher className='only-desktop' isScc={activeTab === PathId.SCC} />

                    {!activeTab ? (
                        <MenuButton type='LTD' user={user as TUser} />
                    ) : activeTab === PathId.CLD ? (
                        <div>
                            <div className={classes.smallDevicesOnly}>
                                <MenuButton type='CLD' user={user as TUser} />
                            </div>
                            <div className={classes.largeDeviceOnly}>
                                <CldMenu />
                            </div>
                        </div>
                    ) : activeTab === PathId.SCC ? (
                        <div>
                            <div className={classes.smallDevicesOnly}>
                                <MenuButton type='SCC' user={user as TUser} />
                            </div>
                        </div>
                    ) : null
                    //     <CldMenu user={user as TUser} /> : null
                    }
                </div>
            </AppBar>
        );
    }
}

class _CLDDDashboardTabs extends Component<
    WithStyles<typeof STYLES> &
        RouteComponentProps & {
            visible: boolean;
            cldPermissions: ICldPermissions;
        }
> {
    getActiveTab = () => {
        const { cldPermissions } = this.props;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        const _DASHBOARD_ROUTES = _.filter(
            CLD_DASHBOARD_ROUTES,
            option => _.indexOf(cldPermissions.dashboardTabs, option.key) > -1
        );
        let index = _.findIndex(_DASHBOARD_ROUTES, option => {
            const regex = new RegExp(option.path + '.*', 'ig');
            return regex.test(location);
        });
        return index > -1 ? index : 0;
    };

    render() {
        const { classes, visible, cldPermissions } = this.props;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        const _DASHBOARD_ROUTES = _.filter(
            CLD_DASHBOARD_ROUTES,
            option => _.indexOf(cldPermissions.dashboardTabs, option.key) > -1
        );

        let activeTab = this.getActiveTab();

        return (
            <Tabs
                centered
                value={activeTab}
                TabIndicatorProps={{ color: 'secondary' }}
                classes={{
                    root: classNames(classes.tab, classes.mainTab, 'print-hidden', visible ? '' : classes.hidden),
                    indicator: activeTab === PathId.SCC ? classes.sccTabIndicator : classes.tabIndicator,
                    flexContainer: classes.rootLTDTab,
                }}
            >
                {_.map(_DASHBOARD_ROUTES, (tab, i) => (
                    <Link
                        className={i === activeTab ? classes.tabSelected : classes.tabInactive}
                        key={_.uniqueId(tab.path)}
                        to={tab.path}
                    >
                        <Tab
                            style={{ fontSize: 14 }}
                            classes={{
                                labelContainer: classes.tabs,
                                selected: classes.tabSelected,
                            }}
                            label={tab.name}
                        />
                    </Link>
                ))}
            </Tabs>
        );
    }
}

class _LTDDashboardTabs extends Component<
    WithStyles<typeof STYLES> &
        RouteComponentProps & {
            visible: boolean;
            ltdPermissions: ILtdPermissions;
        }
> {
    getActiveTab = () => {
        const { ltdPermissions } = this.props;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        const _DASHBOARD_ROUTES = _.filter(
            DASHBOARD_ROUTES,
            option => _.indexOf(ltdPermissions.dashboardTabs, option.key) > -1
        );
        let index = _.findIndex(_DASHBOARD_ROUTES, option => {
            const regex = new RegExp(option.path + '.*', 'ig');
            return regex.test(location);
        });
        return index > -1 ? index : 0;
    };

    render() {
        const { classes, visible, ltdPermissions } = this.props;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        const _DASHBOARD_ROUTES = _.filter(
            DASHBOARD_ROUTES,
            option => _.indexOf(ltdPermissions.dashboardTabs, option.key) > -1
        );

        let activeTab = this.getActiveTab();

        return (
            <Tabs
                centered
                value={activeTab}
                TabIndicatorProps={{ color: 'secondary' }}
                classes={{
                    root: classNames(classes.tab, classes.mainTab, 'print-hidden', visible ? '' : classes.hidden),
                    indicator: activeTab === PathId.SCC ? classes.sccTabIndicator : classes.tabIndicator,
                    flexContainer: classes.rootLTDTab,
                }}
            >
                {_.map(_DASHBOARD_ROUTES, (tab, i) => (
                    <Link
                        className={i === activeTab ? classes.tabSelected : classes.tabInactive}
                        key={_.uniqueId(tab.path)}
                        to={tab.path}
                    >
                        <Tab
                            style={{ fontSize: 14 }}
                            classes={{
                                labelContainer: classes.tabs,
                                selected: activeTab === PathId.SCC ? classes.sccTabSelected : classes.tabSelected,
                            }}
                            label={tab.name}
                        />
                    </Link>
                ))}
            </Tabs>
        );
    }
}

class _SCCDashboardTabs extends Component<
    WithStyles<typeof STYLES> &
        RouteComponentProps & {
            visible: boolean;
        }
> {
    getActiveTab = () => {
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        let index = _.findIndex(SCC_DASHBOARD_ROUTES, option => {
            const regex = new RegExp(option.path + '.*', 'ig');
            return regex.test(location);
        });
        return index > -1 ? index : 0;
    };

    render() {
        const { classes, visible } = this.props;
        const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');

        let activeTab = this.getActiveTab();

        return (
            <Tabs
                centered
                value={activeTab}
                TabIndicatorProps={{ color: 'secondary' }}
                classes={{
                    root: classNames(
                        classes.tab,
                        classes.sccTab,
                        classes.mainTab,
                        'print-hidden',
                        visible ? '' : classes.hidden
                    ),
                    indicator: classes.sccTabIndicator,
                    flexContainer: classes.rootLTDTab,
                }}
            >
                {_.map(SCC_DASHBOARD_ROUTES, (tab, i) => (
                    <Link
                        className={i === activeTab ? classes.sccTabSelected : classes.sccTabInactive}
                        key={_.uniqueId(tab.path)}
                        to={tab.path}
                    >
                        <Tab
                            style={{ fontSize: 14 }}
                            classes={{
                                labelContainer: classes.tabs,
                                selected: classes.sccTabSelected,
                            }}
                            label={tab.name}
                        />
                    </Link>
                ))}
            </Tabs>
        );
    }
}

interface LinkItem {
    title: string;
    url: string;
    target?: string;
    isExternal?: boolean;
}

interface MenuButtonProps extends WithStyles<typeof STYLES>, WithTranslationProps {
    user: TUser;
    type: 'CLD' | 'LTD' | 'SCC';
}

class _MenuButton extends Component<MenuButtonProps> {
    state = {
        anchorEl: null,
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => this.setState({ anchorEl: event.currentTarget });
    handleClose = () => this.setState({ anchorEl: null });

    render() {
        const { classes, user, type, getTranslation, locale } = this.props;
        const lexsitusLocale = locale === 'fr' ? 'fr' : 'en';
        const { anchorEl } = this.state;

        const MENU_SECTIONS: Array<Array<LinkItem>> = [
            [
                { title: getTranslation(MENU_LIST_TUTORIAL_FILMS) || 'Tutorial films', url: '/tutorials/' },
                { title: getTranslation(MENU_LIST_PERSISTENT) || 'Persistent URLs (PURLs)', url: '/purl' },
                {
                    title:
                        getTranslation(MENU_LIST_HOWTOUSE_PURL) ||
                        'How to use the PURLs in the ICC Legal Tools Database?',
                    url: '/how-to-use-the-purls-in-the-icc-legal-tools-database',
                },

                // { title: 'Infographics', url: '/purl/infographics/', target: '_blank' },
            ],
            [
                {
                    title: getTranslation(MENU_LIST_LEGAL_TOOLS) || 'What are the ICC Legal Tools?',
                    url: '/what-are-the-icc-legal-tools',
                },
            ],
            [
                {
                    title:
                        getTranslation(MENU_LIST_LEGAL_CORDANATION) ||
                        'Co-ordination, CMN Fellows, and institutional partners',
                    url: '/coordination',
                },
                { title: getTranslation(MENU_LIST_LTAC) || 'LTAC', url: '/status-of-work-on-the-tools/ltac' },
                {
                    title: getTranslation(MENU_LIST_STATUS_WORK) || 'Status of work on the Tools',
                    url: '/status-of-work-on-the-tools',
                },
                {
                    title: getTranslation(MENU_LIST_RESPONSIBILITY) || 'Table of responsibilities',
                    url: '/status-of-work-on-the-tools/table-of-responsibilities',
                },
                { title: getTranslation(MENU_LIST_BACKGROUND) || 'Background', url: '/background' },
            ],
            // { title: 'Comments and contributions', url: '/comments-and-contributions' },
            [
                {
                    title: getTranslation(MENU_LIST_OPENACCESS) || 'Open access interview',
                    url: '/open-access-interview',
                    target: '_blank',
                },
                {
                    title: getTranslation(MENU_LIST_BOOKONTHE) || 'Book on the Legal Tools',
                    url: 'https://www.toaep.org/ps-pdf/8-bergsmo',
                    target: '_blank',
                    isExternal: true,
                },
                {
                    title:
                        getTranslation(MENU_LIST_THE_PUBILC) ||
                        'The public commons of international criminal law information',
                    url: 'https://www.toaep.org/pbs-pdf/114-bergsmo/',
                    target: '_blank',
                    isExternal: true,
                },
                {
                    title: getTranslation(MENU_LIST_LEXSISTUS) || 'Lexsitus',
                    url: `/lexsitus`,
                },
                {
                    title:
                        getTranslation(MENU_LIST_COMMENTARY) ||
                        'Commentary on the Law of the International Criminal Court (CLICC)',
                    url: `/${lexsitusLocale}/clicc`,
                    target: '_blank',
                },
                {
                    title:
                        getTranslation(MENU_LIST_COPPERATION) || 'Cooperation and Judicial Assistance Database (CJAD)',
                    url: '/cjad',
                    target: '_blank',
                },
                {
                    title: getTranslation(MENU_LIST_NATIONAL) || 'National Implementing Legislation Database (NILD)',
                    url: 'https://iccdb.hrlc.net/data/',
                    target: '_blank',
                    isExternal: true,
                },
                {
                    title: getTranslation(MENU_LIST_SCC) || 'Special Criminal Court (CAR) Database (CPS/SCC)',
                    url: window.location.origin + '/scc',
                    target: '_blank',
                    isExternal: true,
                },
                {
                    title: getTranslation(MENU_LIST_IDOC) || 'I-DOC',
                    url: '/i-doc',
                },
            ],
            [
                {
                    title: getTranslation(MENU_LIST_ICC_MAIN_MENU) || 'ICC main menu',
                    url: '/icc-main-menu',
                    target: '_blank',
                },
                // { title: 'Training', url: '/training-and-contact' },
                { title: getTranslation(MENU_LIST_CONTACT_US) || 'Contact us', url: '/contact-us' },
            ],
        ];

        return (
            <>
                <Button
                    className={type === 'SCC' ? classes.sccMenuButton : classes.menuButton}
                    aria-controls='menu'
                    aria-haspopup='true'
                    variant='outlined'
                    onClick={this.handleClick}
                >
                    <span className='only-desktop'>
                        {getTranslation(MENU_LIST_MENU)}
                        &nbsp;
                    </span>
                    <i className='material-icons'>menu</i>
                </Button>

                <Popover
                    id='menu'
                    elevation={1}
                    // PaperProps={{ className: classes.menuContainer }}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={classes.menuContainer}>
                        <div className={classNames(classes.menuHeader, 'flex-row space-between align-center')}>
                            <Typography>
                                <b>MENU</b>
                            </Typography>
                            <Button onClick={this.handleClose}>
                                <i className='material-icons'>close</i>
                            </Button>
                        </div>

                        <div className='my-4' style={{ paddingRight: '10px' }}>
                            <div className={classNames(classes.menuSection, classes.smallDevicesOnly)}>
                                <LocaleSwitcher className={classes.localeSwitcherSm} isScc={type === 'SCC'} />
                                <UserControls
                                    buttonVariant='outlined'
                                    user={user}
                                    variant={type === 'CLD' ? 'cld' : type === 'LTD' ? 'ltd' : 'scc'}
                                />
                            </div>
                            {type === 'LTD' ? (
                                <>
                                    {MENU_SECTIONS.map((section, i) => (
                                        <div
                                            key={i}
                                            className={classNames(
                                                classes.menuSection,
                                                i === MENU_SECTIONS.length - 1 ? 'no-border' : ''
                                            )}
                                        >
                                            <ul className={classes.ul}>
                                                {section.map(item => (
                                                    <li key={item.title} onClick={this.handleClose}>
                                                        {item.isExternal ? (
                                                            <a href={item.url} target='_blank'>
                                                                {item.title}
                                                            </a>
                                                        ) : (
                                                            <Link to={item.url} target={item.target}>
                                                                {item.title}
                                                            </Link>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                    <div className={classNames(classes.menuSection, classes.smallDevicesOnly)}>
                                        <LtdReportIssue />
                                    </div>
                                </>
                            ) : (
                                <div className={classNames(classes.menuSection, classes.smallDevicesOnly)}>
                                    <CldMenu />
                                </div>
                            )}
                        </div>
                    </div>
                </Popover>
            </>
        );
    }
}

const mapStateToProps = state => ({
    ..._.pick(state.Auth, ['user', 'ltdPermissions', 'cldPermissions']),
});

const mapDispatchToProps = dispatch => ({
    cldResetFilters: () => dispatch(OCLDDocuments.resetValues()),
});

const STYLES = (theme: MuiTheme) =>
    createStyles({
        root: {
            position: 'absolute',
            height: 50,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            transition: '200ms linear',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px',
            },
        },
        largeDeviceOnly: {
            [theme.breakpoints.down('sm')]: {
                display: 'none !important',
            },
        },
        userControlContainer: {
            display: 'flex',
        },
        smallDevicesOnly: {
            [theme.breakpoints.up('sm')]: {
                display: 'none !important',
            },
        },
        tab: {
            flex: 1,
            padding: '0px 15px',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            '& p': {
                color: Theme.Colors.Grayscale.White,
            },
            '& a': {
                textDecoration: 'none',
            },
            '& button': {
                minWidth: 0,
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        sccTab: {
            '& p': {
                color: '#015BA9',
            },
        },
        rootTab: {
            // width: 80,
            gap: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            '& button': {
                fontSize: 16,
            },
        },
        rootLTDTab: {
            // width: 370,
            '& a': {
                margin: '0px 6px',
            },
            display: 'flex',
            justifyContent: 'space-between',
            '& button': {
                fontSize: 16,
            },
        },
        tabs: {
            padding: 0,
        },
        mainTab: {
            justifyContent: 'center',
            flex: 1,
            position: 'relative',
            top: 1,
            '& span': {
                fontWeight: 'bold',
            },
        },
        rootLTDCLDTab: {
            maxWidth: 200,
        },
        hidden: {
            display: 'none',
        },
        logo: {
            '& > p': {
                fontSize: 24,
                fontWeight: 'bold',
            },
        },
        titleText: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px !important',
            },
        },
        logoTitleCLD: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
                // fontSize: '12px !important',
            },
        },
        logoTitleLTD: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        tabIndicator: {
            background: Theme.Colors.Grayscale.White,
            height: 4,
        },
        tabInactive: {
            color: fade(Theme.Colors.Grayscale.White, 0.6),
        },
        tabSelected: {
            color: Theme.Colors.Grayscale.White,
        },
        menuButton: {
            color: Theme.Colors.Grayscale.White,
            borderColor: Theme.Colors.Grayscale.White,
        },
        sccTabIndicator: {
            background: Theme.Colors.Tertiary.dark,
            height: 4,
        },
        sccTabInactive: {
            color: fade(Theme.Colors.Grayscale.Black, 0.6),
        },
        sccTabSelected: {
            color: Theme.Colors.Tertiary.dark,
        },
        sccMenuButton: {
            color: Theme.Colors.Grayscale.Black,
            borderColor: Theme.Colors.Grayscale.Black,
        },
        menuHeader: {
            paddingLeft: 20,
            paddingRight: 10,
        },
        menuContainer: {
            width: 280,
            boxSizing: 'border-box',
            '& button': {
                // padding: 0,
                minWidth: 40,
                minHeight: 40,
                // borderRadius: 20,
                '& i': {
                    fontSize: 20,
                },
            },
        },
        localeSwitcherSm: {
            marginBottom: 32,
            '& > *': {
                color: Theme.Colors.Grayscale.Black,
            },
            '& svg': {
                color: 'black !important',
            },
        },
        menuSection: {
            padding: '10px 0px',
            borderBottom: `1px dashed ${fade('#000', 0.15)}`,
            '&.no-border': {
                borderBottom: 'none',
            },
        },
        ul: {
            marginBlockStart: 0,
            marginBlockEnd: 0,
            // padding: '12px 0px',
            listStyle: 'none',
            '& li': {
                display: 'table',
            },
            '& li::before': {
                content: "' '",
                transform: 'translateY(-45%)',
                background: Theme.Colors.Secondary.main,
                display: 'inline-block',
                width: 4,
                height: 4,
                borderRadius: 2,
                marginRight: 10,
                marginLeft: '-1em',
            },
            '& li > a': {
                textDecoration: 'none',
                fontSize: 12,
                fontWeight: 'bold',
                color: Theme.Colors.TextColor,
                display: 'table-cell',
                lineHeight: '16px',
            },
        },
    });
const MenuButton = withStyles(STYLES)(withTranslations(_MenuButton));
const LTDDashboardTabs = withRouter(withStyles(STYLES)(_LTDDashboardTabs));
const CLDDashboardTabs = withRouter(withStyles(STYLES)(_CLDDDashboardTabs));
const SCCDashboardTabs = withRouter(withStyles(STYLES)(_SCCDashboardTabs));

export default withRouter(
    withTranslations(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(AppHeader)))
);
