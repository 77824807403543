import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Route, Switch, RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import LandingPage from '../screens/Landing';
import Page404 from '../screens/Page404';
// import LTD from '../screens/LTD';
// import CLD from '../screens/CLD';
import ReadingList from '../screens/ReadingList';
import DocumentsList from '../screens/ReadingList/DocumentsList';
// import Policy from '../screens/Policy';
// import Terms from '../screens/Policy/Terms';
import ResetPassword from '../screens/ResetPassword';
import PageContent from '../screens/StaticPages/page-content';
import StaticPage from '../screens/StaticPages/StaticPage';

import LTDDashboard from '../screens/LTDDashboard';
import SCCDashboard from '../screens/SCCDashboard';
import { LinearProgress } from '@material-ui/core';
import CLDDashboard from '../screens/CLDDashboard';

const PrintView = React.lazy(() => import('../screens/ReadingList/PrintView'));
const TableOfResponsibilities = React.lazy(() => import('../screens/StaticPages/TableOfResponsibilities'));
const CldHelp = React.lazy(() => import('../screens/CldHelp'));
const RedirectHandler = React.lazy(() => import('./RedirectHandler'));
const Settings = React.lazy(() => import('../screens/Settings'));
const LTD = React.lazy(() => import('../screens/LTD'));
const CLD = React.lazy(() => import('../screens/CLD'));
const SCC = React.lazy(() => import('../screens/SCC'));
const Policy = React.lazy(() => import('../screens/Policy'));
const Terms = React.lazy(() => import('../screens/Policy/Terms'));

interface Props extends RouteComponentProps {}

class Appnavigation extends Component<Props> {
    render() {
        return (
            <div>
                <React.Suspense fallback={<LinearProgress />}>
                    <Switch>
                        {/* Print view route - should be before other routes to bypass RootLayout */}
                        <Route
                            path='/reading-list/print/:id'
                            render={props => (
                                <div style={{ background: '#fff', minHeight: '100vh' }}>
                                    <PrintView {...props} classes={{} as any} />
                                </div>
                            )}
                        />

                        {/* Regular routes */}
                        <Route exact path='/' render={() => <LTD />} />
                        <Route path='/en' component={RedirectHandler} />
                        <Route exact path='/cld/terms' render={() => <Terms />} />
                        <Route path='/cld/' render={() => <CLD />} />
                        <Route exact path='/scc' render={() => <SCC />} />
                        <Route exact path='/readinglist' render={() => <ReadingList />} />
                        <Route exact path='/settings' render={() => <Settings />} />
                        <Route exact path='/privacy' render={() => <Policy />} />
                        <Route exact path='/reset-password/:token' component={ResetPassword} />
                        <Route exact path='/cld-help' component={CldHelp} />
                        <Route path='/readinglist/:id' exact component={DocumentsList} />
                        <Route
                            path='/training-and-contact/tutorial-film/'
                            exact
                            render={() => <Redirect to='/tutorials' />}
                        />
                        {/** Static Pages */}
                        {_.map(PageContent, (value, key) => (
                            <Route key={_.snakeCase(key)} exact path={key} component={StaticPage} />
                        ))}
                        {/* <Route path="/status-of-work-on-the-tools/table-of-responsibilities" component={TableOfResponsibilities} /> */}

                        <Route path='/en/browse/ltfolder/' component={RedirectToHome} />
                        <Route path='/browse/ltfolder/' component={RedirectToHome} />

                        <Route path='/ltd-dashboard' component={LTDDashboard} />
                        <Route path='/cld-dashboard' component={CLDDashboard} />
                        <Route path='/scc-dashboard' component={SCCDashboard} />
                    </Switch>
                </React.Suspense>
            </div>
        );
    }
}

function RedirectToHome() {
    return <Redirect to='/' />;
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appnavigation));
