import React, { Component, Fragment } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Paper,
    Typography,
    Checkbox,
    Button,
    Collapse,
    Tooltip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TCLDDocument, TMetadata, TSearchResult } from '../../Features/CLDDocuments/@types';
import Divider from '../Divider';
import { Link } from 'react-router-dom';
import { ODocuments } from '../../Features/LTDDocuments/redux-config';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../Resources/Theme';
import { OApp, TToastVariant, TAppState } from '../../RootReducer/AppReducer';
import utilities from '../../Resources/Utils';
import { HISTORY } from '../../screens/RootLayout';
import Bookmark from '../Bookmark';
import { TReadingList } from '../../Features/ReadingList/@types';
import Text from '../Text';
import { TState } from '../../RootReducer/index';
import { value } from 'popmotion';
import moment from 'moment';
import classNames from 'classnames';
import {
    APPEAL_CONFIG,
    OCLDDocuments,
    getLegalFindingMetaDetail,
    LIST_ITEM_COLOR,
    INPUT_PROGRESS_CONFIG,
    getDownloadUrl,
} from '../../Features/CLDDocuments/redux-config';
import DecisionListItem from './DecisionListItem';
import { TUser, ICldPermissions } from '../../Features/Authentication/@types';
import { getCLDUserRole } from '../../Features/Authentication/redux-config';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { getSearchResultHighlightConfig, orderHighlightByFields } from './listingUtils';
import { TOOLTIPS } from '../../Features/CLDDocuments/tooltips';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { CldField } from '../../Services/CldFields.types';
import {
    CARD_COPY_URL,
    GENERAL_DOWNLOAD,
    CARD_METADATA,
    CARD_REMOVE_FROM_LIST,
    CARD_SEE_FULL,
    TOAST_LINK_COPIED_TO_CLIPBOARD,
} from '../../Features/Translations/translationKeys.cld';
import { getListItemActionLabelTranslation } from '../../Features/CLDDocuments/utils';

interface Props extends WithStyles<typeof STYLES>, IDispatchProps, WithTranslationProps {
    readingListItem?: boolean;
    item: TSearchResult;
    itemNumber?: number;
    totalItems?: number;
    subtext?: string;
    onRemoveFromReadingList?: () => void;
}

interface IStateProps {
    showMatchedText: boolean;
    isDecisionSearch?: boolean;
    checkedIds: Array<string>;
    user: TUser;
    cldPermissions: ICldPermissions;
    cldFields: CldField[];
}

interface IDispatchProps {
    openDocument: (id: string, closeRedirect?: string) => void;
    showToast: Function;
    toggleCheck: (id: string) => void;
    deleteLegalFinding: (id) => void;
    newLegalFindingCopy: (id: string) => void;
    confirmationDialog: (message: string, onConfirm: () => void, onDelete: () => void) => void;
}

// const labels = [
//     { name: 'Date created', key: "Date created" },
//     { name: 'Language(s)', key: 'metadata_language_s' },
//     { name: 'External identifier', key: 'metadata_source' },
//     { name: 'Persistent URL', key: 'metadata_persistent_url' },
// ]

const labels = ['Date created', 'Language(s)', 'Source', 'Persistent URL'];

type TAction = 'COPY URL' | 'SEE FULL' | 'DOWNLOAD' | 'READ LATER' | 'METADATA' | 'REMOVE FROM LIST';

const actions: Array<TAction> = ['COPY URL', 'SEE FULL', 'METADATA', 'DOWNLOAD', 'READ LATER'];
const readingListActions: Array<TAction> = [
    'COPY URL',
    'SEE FULL',
    'METADATA',
    // 'DOWNLOAD',
    'REMOVE FROM LIST',
];

class ListItem extends Component<Props & IStateProps> {
    openDocument = () => {
        const { readingListItem, item } = this.props;
        const document = item.hit;
        const id = !_.isEmpty(document.slug) ? document.slug : document.id;
        if (!id) return;
        const closeRedirect = readingListItem ? window.location.pathname : undefined;
        this.props.openDocument(id, closeRedirect);
        HISTORY.push(`/clddoc/${id}/`);
    };

    getTranslatedLabel = (action: TAction) => {
        const { getTranslation } = this.props;
        return getListItemActionLabelTranslation(action, getTranslation);
    };

    actionClicked = (action: TAction) => {
        const { item, readingListItem, getTranslation } = this.props;
        const document = item.hit;
        const closeRedirect = readingListItem ? window.location.pathname : undefined;

        switch (action) {
            case 'COPY URL': {
                const id = !_.isEmpty(document.slug) ? document.slug : document.id;
                utilities.copyLink(`${window.location.host}/clddoc/${id}/`);
                this.props.showToast(
                    getTranslation(TOAST_LINK_COPIED_TO_CLIPBOARD) || 'Link copied to clipboard',
                    'success'
                );
                break;
            }
            case 'DOWNLOAD': {
                // if (_.isEmpty(document.ltdDoc))
                //     return;

                // const url = `http://www.legal-tools.mithyalabs.com/api/clddocs/${document.id}/pdf`;
                const url = getDownloadUrl(document.id);
                window.open(url, '_blank');
                break;
            }
            case 'READ LATER': {
                break;
            }
            case 'SEE FULL': {
                const id = !_.isEmpty(document.slug) ? document.slug : document.id;
                if (!id) return;
                this.props.openDocument(id, closeRedirect);
                HISTORY.push(`/clddoc/${id}/`);
                break;
            }
            case 'METADATA': {
                const id = !_.isEmpty(document.slug) ? document.slug : document.id;
                if (!id) return;
                this.props.openDocument(id, closeRedirect);
                // HISTORY.push(`/clddoc/${id}/pdf`);
                HISTORY.push(`/clddoc/${id}/metadata`);
                break;
            }
            case 'REMOVE FROM LIST': {
                if (!_.isFunction(this.props.onRemoveFromReadingList)) return;
                this.props.onRemoveFromReadingList();
            }
            default:
                return;
        }
    };

    handleDelete = () => {
        const { item } = this.props;
        if (_.isEmpty(item) || _.isEmpty(item.hit)) return;
        this.props.confirmationDialog(
            'Delete legal finding?',
            () => this.props.deleteLegalFinding(item.hit.id),
            () => null
        );
        // this.props.deleteLegalFinding(item.hit.id);
    };

    handleCopyLegalFinding = () => {
        const { item } = this.props;
        this.props.newLegalFindingCopy(item.hit.id);
    };

    render() {
        const {
            classes,
            cldPermissions,
            item,
            showMatchedText = false,
            isDecisionSearch = false,
            user,
            totalItems,
            subtext,
            checkedIds = [],
            readingListItem = false,
            cldFields,
        } = this.props;

        if (isDecisionSearch && !readingListItem) return <DecisionListItem {...this.props} />;

        const document = item.hit as TCLDDocument;
        if (!document) return <div />;

        const metadetail = getLegalFindingMetaDetail(document, cldFields);
        let _user = user || {};
        const isAdmin = getCLDUserRole(user.roles) === 'ADMIN';

        const hasNoLegalFinding = !!!_.trim(document.legalFindingText) || _.isEmpty(_.trim(document.legalFindingText));
        const legalFindingtext = document.legalFinding;
        const url = `/clddoc/${document.slug || document.id}/`;

        if (_.isEmpty(item)) return <div />;
        return (
            <Paper elevation={0} className={classes.root}>
                <div className={classes.tagSection}>
                    <div className={classes.documentId}>
                        {!readingListItem && (
                            <Checkbox
                                color='primary'
                                checked={checkedIds.indexOf(document.id) > -1}
                                onChange={() => this.props.toggleCheck(document.id)}
                            />
                        )}

                        <Tooltip title={TOOLTIPS['ID']}>
                            <Typography className={classes.documentNumber} variant='caption'>
                                {'Legal finding ' + (document.ID || '')}
                            </Typography>
                        </Tooltip>
                    </div>
                    <div className='flex-row align-center'>
                        {cldPermissions.statusVisibility ? <InputProgress document={document} /> : null}
                        <Tag document={document} />
                    </div>
                </div>
                {subtext && (
                    <div style={{ marginLeft: -20 }}>
                        {readingListItem && subtext && (
                            <Typography variant='caption' style={{ color: 'textSecondary', fontSize: '0.7rem' }}>
                                {subtext}
                            </Typography>
                        )}
                    </div>
                )}
                {!_.isEmpty(item.highlight) && (
                    <div onClick={this.openDocument} className={classes.link}>
                        <Preview classes={classes} item={item} />
                    </div>
                )}
                {_.isEmpty(item.highlight) && (
                    <Fragment>
                        <div className={classNames(classes.leading, 'my-1')}>
                            <Link to={url} className={classes.link}>
                                <Typography
                                    align='justify'
                                    dangerouslySetInnerHTML={{
                                        __html: !hasNoLegalFinding
                                            ? _.truncate(legalFindingtext, {
                                                  length: 400,
                                              })
                                            : 'No legal findings contained in this decision',
                                    }}
                                    color={!_.isEmpty(item.highlight) ? 'textPrimary' : 'inherit'}
                                    className={classes.title}
                                >
                                    {/* {_.truncate(document.legalFinding, { length: 400 })} */}
                                </Typography>
                            </Link>
                        </div>
                        {document.legalFinding && <Divider />}
                    </Fragment>
                )}
                <Typography style={{ fontWeight: 500 }} variant='caption' className='my-1'>
                    {document.Title || _.get(document, 'ltdDoc.title')}
                </Typography>
                <Typography style={{ fontWeight: 500 }} variant='caption' className='my-2'>
                    {metadetail}
                </Typography>

                <div>
                    {_.map(labels, (label, i) => {
                        const metadata = _.find(document.metadata, {
                            name: label,
                        }) as TMetadata;
                        if (!_.isEmpty(metadata))
                            return (
                                <div key={i} className={classes.listItem}>
                                    <Typography className={classes.label}>{label} :&nbsp;</Typography>
                                    {/* <Typography className={label === "Persistent URL" ? classes.primary : ''}> {metadata.val}</Typography> */}
                                    <Text linkColor={Theme.Colors.Secondary.main} variant='caption'>
                                        {metadata.val}
                                    </Text>
                                </div>
                            );
                    })}
                </div>
                <div className={classes.footerContainer}>
                    {document.Importance01 ? (
                        <Typography style={{ color: Theme.Colors.Fourth }}>
                            {document.Importance01} importance
                        </Typography>
                    ) : (
                        <div>&nbsp;</div>
                    )}
                    <div className={classes.actionContainer}>
                        {_.map(this.props.readingListItem ? readingListActions : actions, action => {
                            if (action === 'READ LATER')
                                return (
                                    <Bookmark
                                        type='cld'
                                        docType='CldDoc'
                                        classes={{
                                            actionBtn: classes.actionBtn,
                                        }}
                                        color='primary'
                                        key={_.uniqueId('bookmark')}
                                        variant='caption'
                                        id={document.id}
                                    />
                                );
                            else if (action === 'DOWNLOAD')
                                return (
                                    <Button
                                        variant='text'
                                        color='primary'
                                        className={classes.actionBtn}
                                        key={_.uniqueId('btn')}
                                        onClick={e => this.actionClicked(action)}
                                    >
                                        {this.getTranslatedLabel(action)}
                                    </Button>
                                );
                            else
                                return (
                                    <Button
                                        className={classes.actionBtn}
                                        variant='text'
                                        color='primary'
                                        key={_.uniqueId('btn')}
                                        onClick={e => this.actionClicked(action)}
                                    >
                                        {this.getTranslatedLabel(action)}
                                    </Button>
                                );
                        })}
                        {cldPermissions.docEditFn(document) && (
                            <Tooltip title='Create new Legal finding from this document'>
                                <Button onClick={this.handleCopyLegalFinding} className={classes.deleteButton}>
                                    <i className='material-icons'>library_add</i>
                                </Button>
                            </Tooltip>
                        )}
                        {cldPermissions.docDeleteFn(document) && !readingListItem && !document.deleted ? (
                            <Button className={classes.deleteButton} onClick={this.handleDelete}>
                                <i className='material-icons'>delete_outline</i>
                            </Button>
                        ) : null}
                    </div>
                </div>
            </Paper>
        );
    }
}

const _Tag = ({ classes, document = {}, type = 'tag' }) => {
    const appeal = _.get(document, 'Appeal[0]');
    if (!appeal) return <div />;

    const { AppealType } = appeal;
    const tag = _.find(APPEAL_CONFIG, config => config.value === AppealType);
    if (!tag) return null;

    if (type === 'tag')
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='caption' style={{ color: Theme.Colors.TextLight, fontSize: 10 }}>
                    {tag.text}
                </Typography>
                <div
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        marginLeft: 10,
                        background: tag.color,
                    }}
                />
            </div>
        );
    else
        return (
            <Typography
                component='span'
                variant='caption'
                style={{
                    color: 'white',
                    lineHeight: 1,
                    padding: 2,
                    margin: '0px 2px',
                    background: tag.color,
                    display: 'inline-block',
                }}
            >
                {tag.text}
            </Typography>
        );
};

const _InputProgress = ({ classes, document = {} }) => {
    const progress = _.get(document, 'progress');
    if (!progress) return <div />;

    const tag = _.find(INPUT_PROGRESS_CONFIG, config => config.value === progress);
    if (!tag) return null;

    const rightBorder = !_.isEmpty(_.get(document, 'Appeal'));

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
                variant='caption'
                className='mx-2'
                style={{
                    padding: '0px 8px',
                    color: tag.color,
                    fontSize: 10,
                    borderRight: rightBorder ? `1px solid grey` : 'none',
                }}
            >
                {tag.text}
            </Typography>
        </div>
    );
};

interface PreviewProps {
    item: TSearchResult;
}
class Preview extends Component<WithStyles<typeof STYLES> & PreviewProps> {
    state = {
        showPreview: true,
    };

    togglePreview = () => {
        this.setState({ showPreview: !this.state.showPreview });
    };

    getHighlight = (h: string) => {
        const conf = getSearchResultHighlightConfig(_.get(this.props.item, 'hit'), h);
        const pre = <div>{conf.pre}</div>;
        const post = <div>{conf.end}</div>;

        return (
            <>
                {pre}
                <Typography dangerouslySetInnerHTML={{ __html: h }} />
                {post}
            </>
        );
    };

    render() {
        const { classes, item } = this.props;
        const { highlight = [] } = item;
        const { showPreview } = this.state;
        // let highlights: Array<string> = [];
        // _.forEach(highlight, (value, key) => {
        //     _.forEach(value, text => (
        //         highlights.push(text)
        //     ))
        // });
        const highlights = orderHighlightByFields(item.highlight);

        return (
            <div>
                {/* <Button style={this.style.button} onClick={this.togglePreview}>
                    <i style={{
                        color: 'white',
                        transition: '200ms linear',
                        transform: showPreview ? 'rotateZ(180deg)' : ''
                    }}
                        className="material-icons">
                        keyboard_arrow_down
                    </i>
                </Button> */}
                <Collapse in={showPreview}>
                    <div className={classes.previewContainer}>
                        {// _.map(_.slice(highlights, 0, 4), h => (
                        _.map(highlights, h => (
                            <Typography className={classes.previewText} key={_.uniqueId('-highlight')} component='div'>
                                {this.getHighlight(h)}
                            </Typography>
                        ))}
                    </div>
                </Collapse>
                <Divider />
            </div>
        );
    }
}

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Documents, ['showMatchedText']),
    ..._.pick(state.CLDDocuments, ['isDecisionSearch']),
    ..._.pick(state.Auth, ['user']),
    checkedIds: _.get(state.CLDDocuments, 'selected'),
    cldPermissions: _.get(state.Auth, 'cldPermissions'),
    cldFields: state.CldFields.cldFields,
});

const mapDispatchToProps = dispatch => ({
    openDocument: (id: string, closeRedirect?: string) =>
        dispatch(ODetail.openDocument(id, 'CLD', false, closeRedirect)),
    toggleCheck: (id: string) => dispatch(OCLDDocuments.toggleSelect(id)),
    showToast: (message, variant: TToastVariant) => dispatch(OApp.showToast(message, variant)),
    deleteLegalFinding: (id: string) => dispatch(OCLDDocuments.deleteLegalFinding(id)),
    newLegalFindingCopy: (id: string) => dispatch(ONewLegalFinding.copyLegalFinding(id)),
    confirmationDialog: (message: string, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
});

const STYLES = theme =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'relative',
            padding: '10px 32px 0px 32px',
            margin: '5px 0px',
            transition: '200ms linear',
            '&:hover': {
                boxShadow: `0px 1px 3px ${fade(Theme.Colors.Fourth, 0.3)}`,
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8px 10px 0px 32px',
            },
        },
        tagSection: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            top: -8,
            justifyContent: 'space-between',
        },
        documentId: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            left: -26,
            '& span': { padding: '0px 2px' },
        },
        documentNumber: {
            background: LIST_ITEM_COLOR.legalFinding,
            color: 'white',
            fontWeight: 500,
            padding: '0px 2px',
        },
        primary: {
            color: Theme.Colors.Secondary.main,
        },
        extraPreview: {},
        listItem: {
            display: 'flex',
            '& p': {
                fontSize: 12,
            },
        },
        title: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.4,
            color: Theme.Colors.Third,
        },
        leading: {
            display: 'flex',
            alignItems: 'center',
            '& > span': {
                padding: 0,
                marginRight: 4,
            },
        },
        label: {
            fontWeight: 500,
        },
        link: {
            cursor: 'pointer',
            textDecoration: 'none',
            width: '100%',
        },
        previewContainer: {
            // paddingBottom: 16,
            margin: '4px 0px',
            '& span.search-highlight': {
                background: Theme.Colors.Highlight,
            },
            '& > div': {
                fontSize: 14,
                fontWeight: 500,
                margin: '4px 0px',
            },
        },
        actionBtn: {
            color: Theme.Colors.Third,
        },
        actionContainer: {
            marginTop: 8,
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
            '& button': {
                fontSize: 12,
                transition: '300ms linear',
                borderRadius: 0,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 10,
                },
                '&:hover': {
                    background: 'white',
                    boxShadow: `0px 1px 3px ${fade(Theme.Colors.Grayscale.Black, 0.2)}`,
                },
                '& span': {
                    fontWeight: 500,
                    cursor: 'pointer',
                    transition: '500ms linear',
                    // color: Theme.Colors.Third,
                    '&:hover': {
                        // color: Theme.Colors.Third
                    },
                },
            },
        },
        footerContainer: {
            alignItems: 'center',
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                '& > p': {
                    marginTop: 14,
                    position: 'absolute',
                    top: -20,
                    fontSize: 10,
                },
                '& button:first-child': {
                    padding: 0,
                },
            },
        },
        previewText: {
            textAlign: 'justify',
            transition: '200ms linear',
            '&>div, &>p': {
                fontWeight: 400,
                lineHeight: 1.2,
                display: 'inline',
            },
            '&:hover': {
                background: fade(Theme.Colors.Fourth, 0.1),
            },
            // '& em': {
            //     background: Theme.Colors.Highlight,
            // }
        },
        deleteButton: {
            position: 'relative',
            // right: 18,
            // top: -14,
            background: 'white',
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            // border: `1px solid ${Theme.Colors.Error}`,
            '& i': {
                // fontSize: 14,
                color: Theme.Colors.Third,
            },
        },
    });

export const Tag = withStyles(STYLES)(_Tag);
export const InputProgress = withStyles(STYLES)(_InputProgress);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(ListItem)));
