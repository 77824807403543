import { Reducer } from 'redux';
import { TDocumentState } from './@types';
import { TAction, utilities } from '../../Resources/Model';
import {
    DECISION_ASSESSMENT_FACETS,
    DECISION_ASSESSMENT_KEY,
    OCLDDocuments,
    SituationCaseMapping,
} from './redux-config';
import _ from 'lodash';
import { INITIAL_DECISION_SEARCH_FILTER } from './utils';

export type TListHeader = 'RESULTS' | 'LATEST DECISIONS' | 'LATEST LEGAL FINDINGS' | 'LEGAL FINDINGS' | 'DECISIONS';

const initialState: TDocumentState = {
    total: 0,
    documents: [],
    error: false,
    errorMessage: '',
    filter: {},
    recordPerPage: 20,
    isDecisionSearch: false,
    selected: [],
    searchTerm: '',
    sort: 'Relevance',
    listHeader: 'LATEST LEGAL FINDINGS',
    chipList: [],
    treeChips: [],
    facets: Object,
    currentPage: 0,
    highlighterConfig: '',
    searching: false,
    isKeywordSearch: false,
    operation: 'AND',
    sidebarTab: 0,
    keywordText: '',
    showMatchedText: false,
    formData: {},
};

const resetValues: Partial<TDocumentState> = {
    isDecisionSearch: false,
    searchTerm: '',
    error: false,
    errorMessage: '',
    listHeader: 'LATEST LEGAL FINDINGS',
    chipList: [],
    treeChips: [],
    highlighterConfig: '',
    sidebarTab: 0,
    keywordText: '',
    currentPage: 0,
    isKeywordSearch: false,
    operation: 'AND',
    showMatchedText: false,
    formData: {},
};

export let INITIAL_FACETS: Record<string, Array<any>> = {};
export let INITIAL_FACETS_DECISIONS: Record<string, Array<any>> = {};

export const removeIdFromSelection = (listOfIds: Array<string>, idToRemove: string): Array<string> => {
    return listOfIds.filter(id => id !== idToRemove);
};

const sortSituationCase = (situationCases: Array<any>) => {
    const mapping = _.sortBy(
        situationCases.map(situation => {
            const temp = _.find(SituationCaseMapping, { value: situation.key });
            return { ...situation, label: _.get(temp, 'label') };
        }),
        config => config.label
    );

    return _.map(mapping, x => _.omit(x, 'label'));
};

export const initDecisionFacet = async () => {
    try {
        const res = await utilities.request({
            url: 'ltddocs/search',
            params: { filter: INITIAL_DECISION_SEARCH_FILTER },
        });
        INITIAL_FACETS_DECISIONS = _.get(res.data, 'facets');
    } catch (error) {}
};

const mergeFacets = (initialFacets, currentFacets) => {
    let newFacets = { ...currentFacets };
    _.forEach(currentFacets, (v, k) => {
        const values = _.map(_.difference(initialFacets[k], currentFacets[k]), (facet: any) => ({
            ...facet,
            doc_count: 0,
        }));
        newFacets[k] = _.unionBy(v, values, 'key');
    });
    return newFacets;
};

export const CLDDocuments: Reducer<TDocumentState> = (state = initialState, action: TAction): TDocumentState => {
    const {
        CLD_MODEL_ENTITY_RECEIVED,
        CLD_APPLY_FILTER,
        CLD_SET_CURRENT_PAGE,
        CLD_APPLY_SORT,
        CLD_SEARCHING_DOCUMENTS,
        CLD_DOCUMENTS_SEARCH_COMPLETED,
        CLD_SET_LIST_HEADER,
        CLD_SET_CHIP_LIST,
        CLD_APPLY_SEARCH_TERM,
        CLD_SET_SHOW_MATCHED_TEXT,
        CLD_APPLY_OPERATION,
        CLD_SET_DECISION_SEARCH,
        CLD_TOGGLE_DOCUMENT_SELECTION,
        CLD_CLEAR_DOCUMENT_SELECTION,
        CLD_SELECT_ALL_DOCUMENTS,
        CLD_SET_FORM_DATA,
        CLD_UNSET_FORM_DATA,
        CLD_CLEAR_FORM_DATA,
        LEGAL_FINDING_DELETED,
        CLD_SET_KEYWORD_SEARCH,
        CLD_RESET_VALUES,
        CLD_RESET_DOCUMENT_ERROR,
        CLD_SET_KEYWORD_TEXT,
        CLD_DOCUMENT_ERROR,
        CLD_SET_SIDEBAR_TAB,
        CLD_DECISION_FACETS_INIT,
        CLD_SET_RECORD_PER_PAGE,
        CLD_SET_HIGHLIGHTER_CONFIG,
        CLD_RESTORE_SEARCH_STATE,
    } = OCLDDocuments.actions;

    switch (action.type) {
        case CLD_SET_HIGHLIGHTER_CONFIG: {
            return { ...state, highlighterConfig: action.data };
        }
        case CLD_SET_RECORD_PER_PAGE: {
            return { ...state, recordPerPage: action.data };
        }
        case CLD_SET_KEYWORD_TEXT: {
            return { ...state, keywordText: action.data };
        }
        case CLD_SET_SIDEBAR_TAB: {
            return { ...state, sidebarTab: action.data };
        }
        case CLD_DOCUMENT_ERROR: {
            return { ...state, error: true, errorMessage: action.data };
        }
        case CLD_RESET_DOCUMENT_ERROR: {
            return { ...state, error: false, errorMessage: '' };
        }
        case CLD_RESET_VALUES: {
            return { ...state, ...resetValues, filter: {} };
        }
        case CLD_SET_KEYWORD_SEARCH: {
            return { ...state, isKeywordSearch: action.data };
        }
        case LEGAL_FINDING_DELETED: {
            const { documents } = state;
            const remainingDocuments = _.filter(documents, doc => doc.hit.id !== action.data);
            return { ...state, documents: remainingDocuments };
        }
        case CLD_CLEAR_FORM_DATA: {
            return { ...state, formData: {}, searchTerm: '' };
        }
        case CLD_UNSET_FORM_DATA: {
            return { ...state, formData: _.omit(state.formData, action.data) };
        }
        case CLD_SET_FORM_DATA: {
            if (_.isEmpty(action.data.key)) return { ...state };
            return { ...state, formData: { ...state.formData, [action.data.key]: action.data.value } };
        }
        case CLD_SELECT_ALL_DOCUMENTS: {
            return { ...state, selected: action.data };
        }
        case CLD_CLEAR_DOCUMENT_SELECTION: {
            return { ...state, selected: [] };
        }
        case CLD_TOGGLE_DOCUMENT_SELECTION: {
            if (action.data) {
                const id = action.data;
                const selected =
                    state.selected.indexOf(id) > -1
                        ? removeIdFromSelection(state.selected, id)
                        : [...state.selected, id];
                return { ...state, selected };
            }
            return { ...state };
        }
        case CLD_SET_DECISION_SEARCH: {
            const isDecisionSearch = action.data;
            return { ...state, isDecisionSearch };
        }
        case CLD_APPLY_OPERATION: {
            return { ...state, operation: action.data };
        }
        case CLD_APPLY_SEARCH_TERM: {
            return { ...state, searchTerm: action.data };
        }
        case CLD_SET_CHIP_LIST: {
            return { ...state, chipList: action.data };
        }
        case CLD_SET_LIST_HEADER: {
            return { ...state, listHeader: action.data };
        }
        case CLD_SEARCHING_DOCUMENTS: {
            return { ...state, searching: true };
        }
        case CLD_DOCUMENTS_SEARCH_COMPLETED: {
            const facets = _.get(action.data, 'facets');
            if (facets && facets.situationCase) {
                facets.situationCase = sortSituationCase(facets.situationCase);
            }
            if (facets && _.get(facets, 'caseNumber')) {
                facets['caseNumber'] = sortSituationCase(facets['caseNumber']);
            }
            // if (facets && state.isDecisionSearch) {
            //     facets['cld.caseNameFilterEn'] = _.filter(facets['cld.caseNameFilterEn'], f => f.key !== 'None (Situation only)')
            // }
            if (_.isEmpty(INITIAL_FACETS) && !state.isDecisionSearch) INITIAL_FACETS = facets;
            if (_.isEmpty(INITIAL_FACETS_DECISIONS) && state.isDecisionSearch) INITIAL_FACETS_DECISIONS = facets;

            return {
                ...state,
                searching: false,
                documents: _.get(action.data, 'results'),
                total: _.get(action.data, 'total'),
                facets: {
                    ...mergeFacets(state.isDecisionSearch ? INITIAL_FACETS_DECISIONS : INITIAL_FACETS, facets),
                    // [DECISION_ASSESSMENT_KEY]: DECISION_ASSESSMENT_FACETS,
                },
            };
        }
        case CLD_SET_CURRENT_PAGE: {
            return { ...state, currentPage: action.data };
        }
        case CLD_APPLY_FILTER: {
            return { ...state, filter: action.data };
        }
        case CLD_APPLY_SORT: {
            return { ...state, filter: action.data.filter, sort: action.data.sort };
        }
        case CLD_MODEL_ENTITY_RECEIVED: {
            return {
                ...state,
                total: _.get(action.data, 'total'),
                documents: _.get(action.data, 'results'),
                facets: _.get(action.data, 'facets'),
            };
        }
        case CLD_SET_SHOW_MATCHED_TEXT: {
            return { ...state, showMatchedText: action.data };
        }
        case CLD_RESTORE_SEARCH_STATE: {
            return {
                ...state,
                searchTerm: action.data.searchTerm || '',
                isDecisionSearch: action.data.isDecisionSearch,
                formData: {
                    ...state.formData,
                    ...action.data.formData,
                },
            };
        }
        default:
            return { ...state };
    }
};
