import { IDecisionsParams, IDecisionFilter, ILegalFindingsFilter } from './dashboard-types';

export const DECISIONS_FILTER_STORAGE_KEY = 'cld_decisions_filter';
export const LEGAL_FINDINGS_FILTER_STORAGE_KEY = 'cld_legal_findings_filter';

export const DEFAULT_DECISION_FILTER: IDecisionFilter = {
    language: [],
    typeOfDecision: [],
    sortBy: 'dateCreated',
    sortOrder: 'desc',
};

export const DEFAULT_LEGAL_FINDINGS_FILTER: ILegalFindingsFilter = {
    language: [],
    'Decision Type': [],
    progress: [],
    sortBy: 'dateCreated',
    sortOrder: 'desc',
};

export const DEFAULT_DECISIONS_PARAMS: IDecisionsParams = {
    advancedSearchOperator: 'AND',
    term: '',
    filter: {
        highlight: {
            pre_tags: ["<span class='search-highlight'>"],
            post_tags: ['</span>'],
            type: 'unified',
            fields: {
                _all: { number_of_fragments: 3, fragment_size: 300 },
                legalFinding: { number_of_fragments: 3, fragment_size: 300 },
                title: { number_of_fragments: 3, fragment_size: 300 },
                'ltdDoc.title': { number_of_fragments: 3, fragment_size: 300 },
                'docPages.text': { number_of_fragments: 3, fragment_size: 300 },
                caseName_en: {},
                externalId: {},
            },
        },
        sort: { dateCreated: { order: 'desc' } },
        where: {
            'languages.name': ['English'],
            exists: { field: '_decision.fullDocumentNumber' },
            deleted: false,
            "_decision.documentTypeId":"620cb5d26a1f575abfb4f848"
        },
        match_phrase: {},
        from: 0,
        limit: 20,
        include: [
            { relation: 'sourceTypes', scope: { fields: ['id', 'title'] } },
            { relation: 'languages', scope: { fields: ['id', 'name'] } },
            { relation: 'relatedDocs', scope: { fields: ['id', 'title', 'slug'] } },
            { relation: 'subjects' },
            { relation: 'phaseOfCases', scope: { fields: ['id', 'title'] } },
            { relation: 'keywords', scope: { fields: ['id', 'title'] } },
            { relation: 'judicialDocuments', scope: { fields: ['id', 'title'] } },
            { relation: 'iccSituations', scope: { fields: ['id', 'title'] } },
            { relation: 'node', scope: { include: [{ relation: 'parent' }] } },
        ],
        should: [
            [
                { term: { newVersionAvailable: false } },
                { bool: { must_not: { exists: { field: 'newVersionAvailable' } } } },
            ],
        ],
    },
};

export const DEFAULT_LEGAL_FINDINGS_PARAMS = {
    advancedSearchOperator: 'AND',
    term: '',
    filter: {
        highlight: {
            pre_tags: ["<span class='search-highlight'>"],
            post_tags: ['</span>'],
            type: 'unified',
            fields: {
                _all: { number_of_fragments: 3, fragment_size: 300 },
                legalFinding: { number_of_fragments: 3, fragment_size: 300 },
                title: { number_of_fragments: 3, fragment_size: 300 },
                'ltdDoc.title': { number_of_fragments: 3, fragment_size: 300 },
                'docPages.text': { number_of_fragments: 3, fragment_size: 300 },
                caseName_en: {},
                externalId: {},
            },
        },
        sort: { dateCreated: { order: 'desc' } },
        where: {
            'cldFieldLanguage.name__en': ['English'],
            deleted: false,
        },
        from: 0,
        limit: 20,
        include: [
            {
                relation: 'ltdDoc',
                scope: {
                    include: [
                        { relation: 'sourceTypes', scope: { fields: ['id', 'title'] } },
                        { relation: 'languages', scope: { fields: ['id', 'name'] } },
                        { relation: 'relatedDocs', scope: { fields: ['id', 'title', 'slug'] } },
                        { relation: 'subjects' },
                        { relation: 'phaseOfCases', scope: { fields: ['id', 'title'] } },
                        { relation: 'keywords', scope: { fields: ['id', 'title'] } },
                        { relation: 'judicialDocuments', scope: { fields: ['id', 'title'] } },
                        { relation: 'iccSituations', scope: { fields: ['id', 'title'] } },
                        { relation: 'node', scope: { include: [{ relation: 'parent' }] } },
                    ],
                },
            },
        ],
    },
};
