import { DEFAULT_DECISION_FILTER, DEFAULT_LEGAL_FINDINGS_FILTER } from './dashboard-constants';
import { IDecisionFilter, ILegalFindingsFilter } from './dashboard-types';

// Get filter from URL search params or return default
export function getStoredDecisionsFilter(): IDecisionFilter {
    try {
        const searchParams = new URLSearchParams(window.location.search);
        const filterParam = searchParams.get('decisionsFilter');
        if (filterParam) {
            return JSON.parse(decodeURIComponent(filterParam));
        }
        return DEFAULT_DECISION_FILTER;
    } catch (error) {
        console.error('Error reading filter from URL:', error);
        return DEFAULT_DECISION_FILTER;
    }
}

export function getStoredLegalFindingsFilter(): ILegalFindingsFilter {
    try {
        const searchParams = new URLSearchParams(window.location.search);
        const filterParam = searchParams.get('legalFindingsFilter');
        if (filterParam) {
            return JSON.parse(decodeURIComponent(filterParam));
        }
        return DEFAULT_LEGAL_FINDINGS_FILTER;
    } catch (error) {
        console.error('Error reading filter from URL:', error);
        return DEFAULT_LEGAL_FINDINGS_FILTER;
    }
}
