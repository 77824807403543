import { STATUS_OF_WORK_CONTENT } from '../../../Resources/constants';

const workToolStatusEn = `	
<div id="content" class="content">
<div id="breadcrumbs">
<p><a href="browse/">Home&nbsp;»&nbsp;</a><a href="status-of-work-on-the-tools/">Status of work on the Tools</a>
</p>
</div>
<div id="getUrlText">
<div style="cursor:pointer;float:right;" title="Collapse tree" id="collapseTree"><i
        class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();"
    style="float:right;"><i class="glyphicon glyphicon-print"></i></a>
</div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:138/header [begin] -->
<div id="c138" class="csc-default">
<!--  Header: [begin] -->
<div class="csc-header csc-header-n1">
    <h1 class="csc-firstHeader">Status of work on the Tools</h1>
</div>
<!--  Header: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:138/header [end] -->

<!--  CONTENT ELEMENT, uid:137/html [begin] -->
<div id="c137" class="csc-default">
<!--  Raw HTML content: [begin] -->
<table class="public">
    <tbody>
        <tr>
            <td>International Criminal Court Documents</td>
            <td align="right">63,108</td>
        </tr>
        <tr>
            <td>Other International(ised) Criminal Jurisdictions</td>
            <td align="right">216,912</td>
        </tr>
        <tr>
            <td>International Legal Instruments</td>
            <td align="right">1,320</td>
        </tr>
        <tr>
            <td>Human Rights Law Decisions and Documents</td>
            <td align="right">8,361</td>
        </tr>
        <tr>
            <td>Other International Law Decisions and Documents</td>
            <td align="right">10,341</td>
        </tr>
        <tr>
            <td>National Criminal Jurisdictions</td>
            <td align="right">20,448</td>
        </tr>
        <tr>
            <td>Publications</td>
            <td align="right">4,059</td>
        </tr>
        <tr>
            <td>United Nations War Crimes Commission</td>
            <td align="right">11,809</td>
        </tr>
        <tr>
            <td>International(ised) Fact-Finding Mandates</td>
            <td align="right">2,008</td>
        </tr>
        <tr>
            <td></td>
            <td align="right"><strong>338,366</strong></td>
        </tr>
    </tbody>
</table>
<!--  Raw HTML content: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:137/html [end] -->

<!--  CONTENT ELEMENT, uid:50/text [begin] -->
<div id="c50" class="csc-default">
<!--  Text: [begin] -->
<p class="bodytext">(Numbers as of 28 November 2024.)</p>
<p class="bodytext"> The ICC Legal Tools Database is being continuously developed by the ICC Legal Tools
    Project, with daily uploading of new documents and search data. The express goal of the Project is to
    include in the ICC Legal Tools Database every legal document - international or national - that a
    practitioner working on core international crimes cases might need.</p>
<p class="bodytext">Metadata (such as the name and date of a document, when it was issued and by whom) have been
    registered for almost all documents in the Database pursuant to a <a
        href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Legal_Tools_Metadata_Manual_version_1-1.pdf"
        target="_blank">Metadata Manual</a> used by the ICC Legal Tools Project.
</p>
<p class="bodytext">The ICC is committed to the further development of the content and technical qualities of
    the Legal Tools. As the work to collect documents, register metadata for each document, and upload the
    documents onto the ICC Legal Tools Database is labour-intensive, and as the ICC is an operational criminal
    court with limited human resources, this work on the Legal Tools is largely being done by actors external to
    the ICC without any cost to the ICC. The external partners use their own human resources and find funding
    themselves for this activity. Their contribution is based on formal co-operation agreements with the ICC.
    The Governments of Austria, Germany, the Netherlands, Norway and Switzerland have contributed to the Legal
    Tools activities of the external partners. <a
        href="status-of-work-on-the-tools/table-of-responsibilities/">Here</a><a id="_anchor_1"
        name="_msoanchor_1"><u></u></a> you see a table showing the distribution of work between the external
    partners.</p>
<!--  Text: [end] -->
</div>
<!--  CONTENT ELEMENT, uid:50/text [end] -->
</div>

<video controls poster="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.thumbnailUrl}" width="100%">
    <source src="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.url}" style="width: 100%;" type="video/mp4">
</video>
    
</div>

`;
export default workToolStatusEn;
