import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Button, Menu, MenuItem, IconButton, Divider, Collapse, Input, Tooltip, Typography, Zoom, Drawer, Radio } from '@material-ui/core'
import { connect } from 'react-redux'
import _ from 'lodash'
import { TReadingList } from '../../Features/ReadingList/@types';
import { OReadingList } from '../../Features/ReadingList/redux-config';
import { TCurrentDocument } from '../../Features/DetailPage/@types';
import { OApp, TToastVariant } from '../../RootReducer/AppReducer';
import { TypographyProps } from '@material-ui/core/Typography';
import Theme from '../../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import { TAuthDialog, TDialogVariant } from '../../Features/Authentication/@types';
import { OAuth } from '../../Features/Authentication/redux-config';
import { ButtonProps } from '@material-ui/core/Button';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { GENERAL_ADD, GENERAL_ADD_TO_READING_LIST, GENERAL_CREATE_NEW_READING_LIST, GENERAL_NEW_READING_LIST, GENERAL_READ_LATER } from '../../Features/Translations/translationKeys.cld';

interface Props extends WithStyles<typeof STYLES>, WithTranslationProps {
    fullWidth?: boolean
    readingList: Array<TReadingList>,
    id: string | Array<string>,
    color?: TypographyProps['color'],
    textCase?: 'title' | 'upper'
    variant?: TypographyProps['variant'],
    docType?: 'CldDoc' | 'LtdDoc'
    type?: 'cld' | 'ltd'
    onActionCompleted?: () => void
}

interface IDispatchProps {
    createReadingList: Function,
    addToList: Function,
    login: Function
}


interface IStateProps {
    userId: string,
    readingList: Array<TReadingList>
}

class Bookmark extends Component<Props & IStateProps & IDispatchProps> {
    state = {
        showAddBtn: false,
        anchorEl: null,
        name: '',
        isAdding: false,
        selected: ''
    }



    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.name !== this.state.name && !this.state.showAddBtn) {
            if (this.state.name !== '')
                this.setState({ showAddBtn: true });
        }
        if (this.state.name === '' && this.state.showAddBtn)
            this.setState({ showAddBtn: false });


    }

    handleClose = () => {
        this.setState({ name: '', isAdding: false, anchorEl: null });
    };

    toggleAdd = () => {
        this.setState({ isAdding: !this.state.isAdding })
    }

    createNewList = async () => {
        const { userId } = this.props;
        const data = {
            name: this.state.name
        }

        const res = await this.props.createReadingList(userId, data);
        this.setState({ selected: _.get(res, 'data.id') || '', name: '' });
        this.toggleAdd();
    }

    openMenu = (e) => {
        if (_.isEmpty(this.props.userId)) {
            this.props.login('login');
            return;
        }
        this.setState({ anchorEl: e.currentTarget });
    }

    add = () => {
        const { docType = 'LtdDoc' } = this.props;
        const { selected } = this.state;
        this.addToReadingList(selected, docType);
    }

    addToReadingList = (listId, docType) => {
        const { userId, id, type = 'ltd' } = this.props;
        if (id == '')
            return;
        // const docId = _.get(currentDocument, 'document.id');
        this.props.addToList(listId, id, docType, type, userId)
        if (this.props.onActionCompleted)
            this.props.onActionCompleted();
        this.handleClose();
    }

    handleChange = (e) => {

        this.setState({ [e.target.name]: e.target.value });
    }

    selectReadingList = (id: string) => {
        this.setState({ selected: this.state.selected === id ? '' : id })
    }


    render() {
        const { classes, getTranslation, textCase = 'upper', readingList, docType = 'LtdDoc', fullWidth = false } = this.props;
        const { anchorEl, name, isAdding, showAddBtn, selected } = this.state;
        const buttonText = textCase === 'upper' ? getTranslation(GENERAL_ADD_TO_READING_LIST).toUpperCase() : getTranslation(GENERAL_READ_LATER)
        return (
            <div>
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    className={classes.actionBtn}
                    onClick={this.openMenu}
                    variant="text"
                    fullWidth={fullWidth}
                    color={this.props.color as ButtonProps['color']}
                >
                    <Typography color="inherit">{buttonText}</Typography>
                    {/* <i className="material-icons">library_add</i> */}
                </Button>
                {/* <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}

                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                > */}
                <Drawer open={Boolean(anchorEl)} onClose={this.handleClose} anchor="right">
                    <div className={classes.drawerContainer}>
                        <Typography variant="body1" className={classes.menuLabel}>{getTranslation(GENERAL_ADD_TO_READING_LIST) || 'Add to reading list'}</Typography>
                        <MenuItem className={classes.control}>
                            {
                                <div>
                                    <Collapse in={!isAdding}>
                                        <div className="flex-row align-center justify-between">
                                            <Button className={classes.actionButton} onClick={this.toggleAdd} color="secondary">
                                                {getTranslation(GENERAL_NEW_READING_LIST) || 'New reading list'}
                                            </Button>
                                        </div>
                                    </Collapse>
                                    <Collapse in={isAdding}>
                                        <div className={classes.inputSection}>
                                            <Input
                                                autoComplete="off"
                                                placeholder={getTranslation(GENERAL_NEW_READING_LIST) || 'New reading list'}
                                                name="name"
                                                value={name || ''}
                                                onChange={this.handleChange}
                                            />
                                            <div style={{ flex: 1 }} />
                                            <IconButton className={classes.iconButton} onClick={this.toggleAdd}>
                                                <i className="material-icons">close</i>
                                            </IconButton>
                                            <Zoom in={showAddBtn}>
                                                <Tooltip title={getTranslation(GENERAL_CREATE_NEW_READING_LIST) || "Create new reading list"} placement="top-end">
                                                    <IconButton className={classes.iconButton} color="secondary" onClick={this.createNewList} >
                                                        <i className="material-icons">check</i>
                                                    </IconButton>
                                                </Tooltip>
                                            </Zoom>
                                        </div>
                                    </Collapse>
                                </div>
                            }
                        </MenuItem>
                        {
                            _.map(readingList, item => (
                                <MenuItem style={{ minHeight: 30 }} key={item.id} className={item.id === selected ? classes.highlight : undefined} dense onClick={e => this.selectReadingList(item.id)}>
                                    <Radio checked={item.id === selected} />
                                    <Typography variant="body2">
                                        {item.name}
                                    </Typography>
                                    <div style={{ flex: 1 }} />
                                    {
                                        item.id === selected ?
                                            <Tooltip title="Add to selected reading list" placement="top-end">
                                                <div>
                                                    <Button style={{ color: 'white' }} onClick={this.add} className={classes.actionButton} variant="contained" color="secondary" disabled={!selected}>
                                                        {getTranslation(GENERAL_ADD) || 'ADD'}
                                                    </Button>
                                                </div>
                                            </Tooltip> : null
                                    }
                                </MenuItem>
                            ))
                        }

                        <Divider />


                    </div>
                </Drawer>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userId: _.get(state.Auth, 'user.id'),
    readingList: _.get(state.ReadingList, 'readingList')
})

const mapDispatchToProps = dispatch => ({
    // showToast: (message, variant: TToastVariant) => dispatch(OApp.showToast(message, variant)),
    login: (variant: TDialogVariant) => dispatch(OAuth.openAuthDialog(variant)),
    createReadingList: (id, formData) => dispatch(OReadingList.createReadingList(id, formData)),
    addToList: (readingListId, docId, docType, type, userId) => dispatch(OReadingList.addToReadingList(readingListId, docId, docType, type, userId))
})

const STYLES = theme => createStyles({
    actionBtn: {
        textTransform: 'none',
        minWidth: 100,
        fontWeight: 500,
        '& p': {
            fontWeight: 500,
            fontSize: 12,
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
            }
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 0
        }
    },
    drawerContainer: {
        width: 300
    },
    control: {
        minHeight: 30,
        position: 'sticky',
        top: 50,
        zIndex: 1,
        background: 'white'
    },
    inputSection: {
        display: 'flex',
        width: '256px',
        padding: '0px 12px'
    },
    menuContainer: {
        // maxHeight: 480,
        // overflow: 'auto',

        // position: 'relative'
    },
    iconButton: {
        padding: 4
    },
    addNewButton: {
        [theme.breakpoints.up('sm')]: {
            // minWidth: 0,
            // padding: 0,
            // minHeight: 0,
            // width: 40,
            // height: 40,
            // borderRadius: 20,
        },

        background: Theme.Colors.Secondary.dark,
        margin: '0 auto',
        color: 'white',
        transition: '200ms linear',
        '&:hover': {
            background: Theme.Colors.Secondary.light,
            boxShadow: `0px 1px 3px ${fade(Theme.Colors.Fourth, 0.3)}`
        }
    },
    highlight: {
        background: fade(Theme.Colors.Secondary.main, 0.2),
        '&:hover': {
            background: fade(Theme.Colors.Secondary.main, 0.4),
        }
    },
    actionButton: {
        minWidth: 0,
        minHeight: 0,
        fontSize: 14,
        fontWeight: 500
    },
    menuLabel: {
        // position: 'relative',
        height: 50,
        fontSize: 18,
        zIndex: 1,
        position: 'sticky',
        top: 0,
        lineHeight: '50px',
        padding: '0px 26px',
        background: Theme.Colors.Secondary.dark,
        color: 'white',
        '&:focus': {
            outline: 'none'
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(withTranslations(Bookmark)))