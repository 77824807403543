import React, { Component } from 'react';
import { createStyles, withStyles, WithStyles, CircularProgress, Typography, Grid, Button } from '@material-ui/core';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import _ from 'lodash';
import moment from 'moment';
import Theme from '../../Resources/Theme';
import { TypographyProps } from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Tag } from '../../Components/CLDList/ListItem';
import { Link } from 'react-router-dom';
import {
    getLegalFindingMetaDetail,
    OCLDDocuments,
    getLegalFindingMetaDetailDetailPage,
} from '../../Features/CLDDocuments/redux-config';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { TState } from '../../RootReducer';
import { connect } from 'react-redux';
import { ONewLegalFinding } from '../../Features/AddNewLegalFinding/redux-config';
import { TEditSection } from '../../Features/AddNewLegalFinding/@types';
import { TUser, ICldPermissions } from '../../Features/Authentication/@types';
import { OApp } from '../../RootReducer/AppReducer';
import utilities from '../../Resources/Utils';
import { getCLDUserRole } from '../../Features/Authentication/redux-config';
import LegalFindingItem from './LegalFindingItem';

interface IProps extends WithStyles<typeof STYLES> {
    data: TCLDDocument;
}

interface IStateProps {
    user: TUser;
    currentPage: number;
    cldPermissions: ICldPermissions;
}

interface IDispatchProps {
    openDocument: (id: string) => void;
    editLegalFinding: (section: TEditSection) => void;
    searchDocuments: (pageNumber: number) => void;
    deleteLegalFinding: (id) => void;
    confirmationDialog: (message: string, onConfirm: () => void, onDelete: () => void) => void;
}

class LegalFinding extends Component<IProps & IStateProps & IDispatchProps> {
    formatData = data => {
        // const legalFindingtext = ONewLegalFinding.getRawTextFromFinding(data);
        return utilities.formatLegalFinding(data);

        // if (!legalFindingtext)
        //     return 'No legal finding in this document.'
        // return utilities.formatLegalFinding(data);
    };

    openDocument = () => {
        const { data } = this.props;
        if (_.isEmpty(data.ltdDoc)) return;
        this.props.openDocument(data.ltdDoc.slug);
    };

    handleDelete = () => {
        const { data, currentPage = 0 } = this.props;
        if (_.isEmpty(data)) return;
        this.props.confirmationDialog(
            'Delete legal finding?',
            () => {
                this.props.deleteLegalFinding(data.id);
                this.props.searchDocuments(currentPage);
            },
            () => null
        );
    };

    render() {
        const { classes, data, user, cldPermissions } = this.props;
        if (_.isEmpty(data))
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 200 }}>
                    <CircularProgress />
                </div>
            );

        const metadetail = getLegalFindingMetaDetailDetailPage(data);
        const badges = [
            { text: `LEGAL FINDING ${data.ID}`, variant: 'normal' },
            { text: `${data.Importance01 ? data.Importance01 + ' importance' : ''}`, variant: 'normal' },
            // { text: `${data.Status}`, variant: 'success' },
        ];

        const APPEALS_LABELS = [
            { label: 'APPEAL TYPE', flex: 2, key: 'AppealType' },
            { label: 'DECISION ID', flex: 2, key: 'Appeal' },
            { label: 'PARAGRAPH(S)', flex: 2, key: 'AppealPara' },
            { label: 'PAGE(S)', flex: 1, key: 'AppealPage' },
        ];
        const RELATED_DECISIONS_LABELS = [
            { label: 'RELATION TYPE', flex: 2, key: 'Relation Type' },
            { label: 'DECISION', flex: 3, key: 'Related Decision', urlKey: 'ltdDocSlug' },
            { label: 'PARAGRAPH(S)', flex: 2, key: 'Related Para' },
            { label: 'PAGE(S)', flex: 1, key: 'Related Pages' },
        ];
        const OPINIONS_LABELS = [
            { label: 'OPINION TYPE', flex: 2, key: 'OpinionType' },
            { label: 'DECISION', flex: 3, key: 'Opinion', urlKey: 'ltdDocSlug' },
            { label: 'PARAGRAPH(S)', flex: 2, key: 'OpinionPara' },
            { label: 'PAGE(S)', flex: 1, key: 'OpinionPage' },
        ];

        return (
            <div id='lf-container' className={classes.root}>
                <div className={classes.contentWrapper}>
                    <div className={classNames(classes.titleContainer, classes.content)}>
                        <Link
                            onClick={this.openDocument}
                            className={classes.link}
                            to={!_.isEmpty(data.ltdDoc) ? `/decision/${data.ltdDoc.slug}` : ''}
                        >
                            <Typography color='primary' gutterBottom variant='subtitle1' className={classes.title}>
                                {data.Title || _.get(data, 'ltdDoc.title')}
                            </Typography>
                        </Link>
                        {/* {(cldPermissions.docEdit) ?
                            <div className={classes.deleteBtnContainer}>
                                <Button className={classes.editBtn} onClick={e => this.props.editLegalFinding('home')}>
                                    <i className="material-icons">edit</i>
                                </Button>
                                
                            </div> : null
                        } */}
                        <Typography className='print-hidden' variant='caption'>
                            {metadetail}
                        </Typography>
                    </div>
                    <div className={classNames(classes.detail, classes.content)}>
                        <div className={classes.badgeContainer}>
                            {_.map(badges, (badge, i) => {
                                if (badge.text === '') return <div key={i} />;
                                return <Badge key={i} text={badge.text} variant={badge.variant} />;
                            })}
                            <Tag document={data} type='badge' />
                            <div className={classes.editBtnContainer}>
                                {cldPermissions.docEditFn(data) ? (
                                    <Button
                                        className={classes.editBtn}
                                        onClick={e => this.props.editLegalFinding('finding')}
                                    >
                                        <i className='material-icons'>edit</i>
                                    </Button>
                                ) : null}
                                {cldPermissions.docDeleteFn(data) ? (
                                    <Button className={classes.deleteButton} onClick={this.handleDelete}>
                                        <i className='material-icons'>delete_outline</i>
                                    </Button>
                                ) : null}
                            </div>
                        </div>

                        <Typography
                            align='justify'
                            className={classNames(classes.legalFindingText, 'ql-editor')}
                            dangerouslySetInnerHTML={{ __html: this.formatData(data.legalFinding) }}
                        ></Typography>
                    </div>
                    {!_.isEmpty(data.Keyword) && <Keywords {...this.props} />}
                    {!_.isEmpty(data.Appeal) && (
                        <MetaTable
                            {...this.props}
                            itemKey='Appeal'
                            labels={APPEALS_LABELS}
                            title='RESULTS OF APPEALS'
                        />
                    )}
                    {!_.isEmpty(data['Related Decision']) && (
                        <MetaTable
                            {...this.props}
                            itemKey='Related Decision'
                            labels={RELATED_DECISIONS_LABELS}
                            title='RELATED DECISIONS'
                        />
                    )}
                    {!_.isEmpty(data.Opinions) && (
                        <MetaTable
                            {...this.props}
                            itemKey='Opinions'
                            labels={OPINIONS_LABELS}
                            title='SEPARATE OR DISSENTING OPINIONS'
                        />
                    )}
                </div>
                {!_.isEmpty(data.ltdDoc) &&
                    !_.isEmpty(data.ltdDoc.includedLegalFindings) &&
                    data.ltdDoc.includedLegalFindings &&
                    data.ltdDoc.includedLegalFindings.some(finding => finding.id !== data.id) && (
                        <div className={classNames(classes.metaContainer, classes.otherFindingsContainer)}>
                            <div className={classNames(classes.titleContainer, classes.content, classes.otherFindingsTitleContainer)}>
                                <Typography
                                    color='primary'
                                    gutterBottom
                                    variant='subtitle1'
                                    className={classNames(classes.title, classes.otherFindingsTitle)}
                                >
                                    OTHER LEGAL FINDINGS IN THIS DECISION
                                </Typography>
                            </div>
                            {_.map(data.ltdDoc.includedLegalFindings, (finding, i) => {
                                if (finding.id === data.id) return null;
                                return (
                                    <div key={i} className={classes.finding}>
                                        <LegalFindingItem item={finding} hideCheckbox={true} />
                                    </div>
                                );
                            })}
                        </div>
                    )}
            </div>
        );
    }
}

const Keywords: React.FC<IProps & IStateProps & IDispatchProps> = props => {
    const { classes, data, user, cldPermissions } = props;

    return (
        <div className={classNames(classes.metaContainer, classes.content)}>
            <div className={classes.badgeContainer}>
                <Badge text='KEYWORDS' variant='normal' />
                {cldPermissions.docEditFn(data) && (
                    <div className={classes.editBtnContainer}>
                        <Button className={classes.editBtn} onClick={e => props.editLegalFinding('keywords')}>
                            <i className='material-icons'>edit</i>
                        </Button>
                    </div>
                )}
            </div>
            {_.map(data.Keyword as string[], (keyword, i) => (
                <Typography style={{ lineHeight: 1.4, marginBottom: 10 }} variant='caption' key={i}>
                    {keyword}
                </Typography>
            ))}
        </div>
    );
};

interface MetaTableProps {
    itemKey: string;
    title: string;
    labels: any;
}

const MetaTable: React.FC<IProps & IDispatchProps & IStateProps & MetaTableProps> = props => {
    const { classes, data, itemKey = '', labels = [], title = '', user, cldPermissions } = props;

    let navigateTo: TEditSection = 'home';
    switch (itemKey) {
        case 'Appeal': {
            navigateTo = 'appeal';
            break;
        }
        case 'Opinions': {
            navigateTo = 'opinions';
            break;
        }
        case 'Related Decision': {
            navigateTo = 'decisions';
            break;
        }
    }

    return (
        <div className={classNames(classes.metaContainer, classes.content)}>
            <div className={classes.badgeContainer}>
                <Badge text={title} variant='normal' />
                {cldPermissions.docEditFn(data) && (
                    <div className={classes.editBtnContainer}>
                        <Button className={classes.editBtn} onClick={e => props.editLegalFinding(navigateTo)}>
                            <i className='material-icons'>edit</i>
                        </Button>
                    </div>
                )}
            </div>
            <div>
                <div className={classNames(classes.borderBottomSolid, classes.row)}>
                    {_.map(labels, (label, i) => (
                        <div key={i} className={classes.column} style={{ flex: label.flex }}>
                            <Typography variant='caption'>{label.label}</Typography>
                        </div>
                    ))}
                </div>
                {_.map(data[itemKey], (appeal, i) => (
                    <div key={i} className={classNames(classes.borderBottom, classes.row)}>
                        {_.map(labels, (label, i) => (
                            <div key={i} className={classes.column} style={{ flex: label.flex }}>
                                {label.urlKey && _.get(appeal, label.urlKey) ? (
                                    <Link to={`/doc/${_.get(appeal, label.urlKey)}/pdf`}>
                                        <Typography color='primary' variant='caption'>
                                            {_.get(appeal, label.key)}
                                        </Typography>
                                    </Link>
                                ) : (
                                    <Typography variant='caption'>{_.get(appeal, label.key)}</Typography>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const Appeals: React.FC<IProps & IStateProps & IDispatchProps> = props => {
    const { classes, data, cldPermissions } = props;
    const LABELS = [
        { label: 'APPEAL TYPE', flex: 2 },
        { label: 'APPEAL DOC ID', flex: 3 },
        { label: 'PAGE', flex: 2 },
        { label: 'PARAGRAPH', flex: 4 },
    ];

    return (
        <div className={classNames(classes.metaContainer, classes.content)}>
            <div className={classes.badgeContainer}>
                <Badge text='RESULTS OF APPEALS' variant='normal' />
                {cldPermissions.docEditFn(data) && (
                    <div className={classes.editBtnContainer}>
                        <Button className={classes.editBtn} onClick={e => props.editLegalFinding('appeal')}>
                            <i className='material-icons'>edit</i>
                        </Button>
                    </div>
                )}
            </div>
            <div>
                <div className={classNames(classes.borderBottomSolid, classes.row)}>
                    {_.map(LABELS, (label, i) => (
                        <div key={i} className={classes.column} style={{ flex: label.flex }}>
                            <Typography variant='caption'>{label.label}</Typography>
                        </div>
                    ))}
                </div>
                {_.map(data.Appeal, (appeal, i) => (
                    <div key={i} className={classNames(classes.borderBottom, classes.row)}>
                        <div className={classes.column} style={{ flex: 2 }}>
                            <Typography variant='caption'>{_.get(appeal, 'AppealType')}</Typography>
                        </div>
                        <div className={classes.column} style={{ flex: 3 }}>
                            <Typography variant='caption'>{_.get(appeal, 'Appeal')}</Typography>
                        </div>
                        <div className={classes.column} style={{ flex: 2 }}>
                            <Typography variant='caption'>-</Typography>
                        </div>
                        <div className={classes.column} style={{ flex: 4 }}>
                            <Typography variant='caption'>{_.get(appeal, 'AppealPara')}</Typography>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Badge = props => {
    let background = Theme.Colors.Fourth;
    if (props.variant === 'success') background = Theme.Colors.Green;
    else if (props.variant === 'error') background = Theme.Colors.Error;

    return (
        <Typography
            component='span'
            variant='caption'
            style={{
                color: 'white',
                lineHeight: 1,
                padding: 2,
                margin: '0px 2px',
                background,
                display: 'inline-block',
            }}
        >
            {props.text}
        </Typography>
    );
};

const mapStateToProps = (state: TState) => ({
    ..._.pick(state.Auth, 'user'),
    ..._.pick(state.CLDDocuments, ['currentPage']),
    cldPermissions: _.get(state.Auth, 'cldPermissions'),
});

const mapDispatchToProps = dispatch => ({
    searchDocuments: pageNumber => dispatch(OCLDDocuments.searchDocuments(pageNumber, false, true)),
    openDocument: id => dispatch(ODetail.openDocument(id, 'LTD', true)),
    editLegalFinding: (section: TEditSection) => dispatch(ONewLegalFinding.openNewLegalFindingDialog(false, section)),
    deleteLegalFinding: (id: string) => dispatch(OCLDDocuments.deleteLegalFinding(id)),
    confirmationDialog: (message: string, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
});

const STYLES = theme =>
    createStyles({
        root: {
            paddingBottom: 100,
        },
        contentWrapper: {
            width: 680,
            boxSizing: 'border-box',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        link: {
            textDecoration: 'none',
        },
        content: {
            background: 'white',
            padding: 20,
            boxSizing: 'border-box',
        },
        titleContainer: {
            position: 'relative',
            borderRadius: 4,
            margin: '20px 0px',
            maxWidth: 680,
        },
        title: {
            color: Theme.Colors.Third,
            fontWeight: 500,
        },
        detail: {
            marginTop: 40,
            borderRadius: 4,
        },
        badgeContainer: {
            position: 'relative',
            top: -30,
        },
        editBtn: {
            background: 'white',
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            border: `1px solid ${Theme.Colors.Third}`,
            '& i': {
                color: Theme.Colors.Third,
            },
        },
        legalFindingText: {
            '& .ql-direction-rtl': {
                direction: 'rtl',
            },
        },
        editBtnContainer: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        deleteBtnContainer: {
            position: 'absolute',
            right: 20,
            top: -10,
        },
        deleteButton: {
            background: 'white',
            width: 30,
            height: 30,
            minHeight: 30,
            minWidth: 30,
            borderRadius: 25,
            marginLeft: 8,
            padding: 0,
            border: `1px solid ${Theme.Colors.Third}`,
            '& i': {
                color: Theme.Colors.Third,
            },
        },
        row: {
            display: 'flex',
            alignItems: 'center',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
        },
        borderBottom: {
            borderBottom: `1px dashed ${Theme.Colors.Grayscale.Grey4}`,
        },
        borderBottomSolid: {
            borderBottom: `1px solid ${Theme.Colors.Grayscale.Grey3}`,
        },
        metaContainer: {
            marginTop: 40,
            '& span': {
                fontWeight: 500,
                margin: '5px 0px',
            },
            '& button > span': {
                margin: 0,
            },
        },
        finding: {
            width: 680,
            borderRadius: 4,
            margin: '0px auto 48px auto',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
        },
        otherFindingsContainer: {
            paddingTop: 20,
            borderTop: `1px dashed ${Theme.Colors.Grayscale.Grey3}`,
        },
        otherFindingsTitle: {
            textAlign: 'center',
        },
        otherFindingsTitleContainer: {
            margin: '0 auto 20px auto',
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(LegalFinding));
