import _ from 'lodash';
import { Models } from "../../Resources/Model";
import { TState } from '../../RootReducer/index';
import utilities from '../../Resources/Utils';
import { OApp } from '../../RootReducer/AppReducer';
import { LTD_INCLUDES } from '../DetailPage/redux-config';
import { TDocument } from '../LTDDocuments/@types';
import { includeValueOptions } from '../LTDDocuments/redux-config';
import { TNode } from '../CLDDocumentTree/@types';
import { LtdDocService } from '../../Services/LtdDocs.service';
import { TLTDDocEditMode } from './@types';





export class NewLTDDocument extends Models {
    constructor() {
        super('NewLTDDocument', {
            OPEN_NEW_LTD_DOCUMENT_DIALOG: 'OPEN_NEW_LTD_DOCUMENT_DIALOG',
            CLOSE_NEW_LTD_DOCUMENT_DIALOG: 'CLOSE_NEW_LTD_DOCUMENT_DIALOG',
            SET_LTD_FORM_FIELD: 'SET_LTD_FORM_FIELD',
            UNSET_LTD_FORM_FIELD: 'UNSET_LTD_FORM_FIELD',
            SAVE_LTD_DOCUMENT_CHANGES: 'SAVE_LTD_DOCUMENT_CHANGES',
            LTD_NEW_RESOURCE_UPLOADED: 'LTD_NEW_RESOURCE_UPLOADED',

            LTD_DOCUMENT_CHANGES_SAVED: 'LTD_DOCUMENT_CHANGES_SAVED',
            EDIT_LTD_DOCUMENT: 'EDIT_LTD_DOCUMENT',
            SET_INCLUDE_FIELD_ID_OPTIONS: 'SET_INCLUDE_FIELD_ID_OPTIONS',

            OPEN_LTD_DOCUMENT_SELECTION_DIALOG: 'OPEN_LTD_DOCUMENT_SELECTION_DIALOG',
            CLOSE_LTD_DOCUMENT_SELECTION_DIALOG: 'CLOSE_LTD_DOCUMENT_SELECTION_DIALOG',
            NEW_LTD_DOCUMENT_CREATED: 'NEW_LTD_DOCUMENT_CREATED',

            LTD_DOCUMENTS_DELETED: 'LTD_DOCUMENTS_DELETED',

            LTD_DOC_PUBLISH: 'LTD_DOC_PUBLISH',
            OPEN_NEW_LTD_PREFILLED_DOCUMENT_DIALOG: 'OPEN_NEW_LTD_PREFILLED_DOCUMENT_DIALOG',

            LTD_DOC_PATH_RECEIVED: 'LTD_DOC_PATH_RECEIVED'
        });
    }




    setFormField = (key: string, value: any) => dispatch => {
        dispatch({
            type: this.actions.SET_LTD_FORM_FIELD,
            data: {
                key,
                value
            }
        })
    }

    removeField = (key: string) => dispatch => {
        dispatch({
            type: this.actions.UNSET_LTD_FORM_FIELD,
            data:
                key

        })
    }


    uploadFile = (file: any) => async dispatch => {
        try {
            // const res = await utilities.uploadFile(
            //     '/ltddocs/upload',
            //     file,
            //     file.name,
            //     'doc'
            // );
            const res = await LtdDocService.uploadFile(file);
            dispatch({
                type: this.actions.LTD_NEW_RESOURCE_UPLOADED,
                data: res.data
            })
            return res.data;
        } catch (error) {
            throw error
        }
    }

    requestDocPath = async (parentId) => {
        if (!parentId)
            return;
        let parentIds;
        if (_.isString(parentId)) {
            const nodeResp = await utilities.request({
                url: `nodes/${parentId}`
            });
            const node = nodeResp.data;
            parentIds = node.parentIds || [];
            parentIds = [...parentIds.slice(1), parentId]
        } else if (_.isArray(parentId))
            parentIds = [...parentId.slice(1)];
        if (_.isEmpty(parentIds))
            return;
        const pathResp = await utilities.request({
            url: `nodes`,
            params: {
                filter: {
                    where: {
                        id: {
                            'inq': parentIds
                        }
                    }
                }

            }
        });
        const nodes = pathResp.data || [];
        let pathNodes: Array<TNode> = [];
        _.each(parentIds, parentId => {
            const pathNode = _.find(nodes, { id: parentId }) as TNode;
            if (!pathNode)
                return true;
            pathNodes.push(pathNode);
        });
        return pathNodes;
    }

    fetchDocPath = (parentId) => async dispatch => {
        const pathNodes = await this.requestDocPath(parentId);
        dispatch({
            type: this.actions.LTD_DOC_PATH_RECEIVED,
            data: {
                docPath: pathNodes
            }
        })

    }
    editLTDDocument = (docId: string, document?: TDocument, shouldFetchDoc: boolean = true, editMode: TLTDDocEditMode = 'doc', additionalData?: any) => async (dispatch) => {
        // const options = await includeValueOptions();

        // dispatch({
        //     type: this.actions.SET_INCLUDE_FIELD_ID_OPTIONS,
        //     data: options
        // })


        try {
            let doc: Partial<TDocument> = {};
            if (shouldFetchDoc) {
                const res = await utilities.request({
                    url: `ltddocs/${docId}`,
                    params: {
                        filter: {
                            include: LTD_INCLUDES
                        }
                    }
                })
                doc = res.data;
            } else {
                doc = { ...document };
            }
            const parentId = _.get(doc, 'node.parentId');
            dispatch({
                type: this.actions.EDIT_LTD_DOCUMENT,
                data: {
                    doc,
                    editMode,
                    additionalData
                }
            });
            dispatch(this.fetchDocPath(parentId));
        } catch (error) {

        }

    }



    deleteLTDDocument = (ids: string[], permanently: boolean = false) => async dispatch => {
        dispatch(OApp.showConfirmationDialog(`Are you sure you want to ${permanently ? 'permanently' : ''} delete the document(s)?`, async () => {

            try {
                const res = await utilities.request({
                    url: 'ltddocs/delete',
                    method: 'DELETE',
                    params: {
                        ids,
                        permanently
                    }
                });
                dispatch({
                    type: this.actions.LTD_DOCUMENTS_DELETED,
                    data: {
                        ids,
                        permanently
                    }
                })
                dispatch(OApp.showToast('Document deleted!', 'success'));
                return res.data
            } catch (error) {
                throw error
            }
        }, () => null))
    }



    updateLTDDraftDocument = (parentNodeId: string, formData: any, subjectId?: string) => async (dispatch, getState) => {
        try {
            const { state } = getState();
            const res = await utilities.request({
                url: 'ltddocs/create',
                method: 'POST',
                data: {
                    parentNodeId,
                    doc: { ...formData, id: subjectId },

                }
            })

            dispatch({
                type: this.actions.LTD_DOCUMENT_CHANGES_SAVED,
                data: {
                    doc: res.data,
                    appConstants: _.get(state, 'App.appConstants')
                }
            });

            dispatch(OApp.showToast("Changes saved successfully", 'success'));

        } catch (error) {
            dispatch(OApp.showToast("Something went wrong. Please try again.", 'error'));
        }
    }

    createNewLTDDocument = (nodeId: string, formData: any) => async dispatch => {
        if (_.isEmpty(nodeId) || _.isEmpty(formData))
            return;

        if (formData.id)
            return dispatch(this.saveChanges(formData.id, _.omit(formData, 'id')));
        try {
            const res = await utilities.request({
                url: 'ltddocs/create',
                method: 'POST',
                data: {
                    parentNodeId: nodeId,
                    doc: formData,

                }
            })

            const editMode: TLTDDocEditMode = _.get(res.data, 'status') === 'draft' ? 'draft' : 'doc';
            const additionalData = editMode === 'draft' ? { parentNodeId: nodeId, subjectId: _.get(res.data, 'req.id') } : undefined;

            dispatch({
                type: this.actions.NEW_LTD_DOCUMENT_CREATED,
                data: { ...formData, id: _.get(res.data, 'id'), editMode, additionalData }
            })
            dispatch(OApp.showToast("Document successfully created", 'success'))

        } catch (error) {
            dispatch(OApp.showToast("Something went wrong. Please try again.", 'error'))
        }
    }



    saveChanges = (docId: string, formData: any) => async (dispatch, getState) => {
        try {
            const { state } = getState();
            const res = await utilities.request({
                url: `ltddocs/${docId}/edit`,
                method: 'PATCH',
                data: formData
            })
            dispatch({
                type: this.actions.LTD_DOCUMENT_CHANGES_SAVED,
                data: {
                    doc: res.data,
                    appConstants: _.get(state, 'App.appConstants')
                }
            });
            dispatch(OApp.showToast("Changes saved successfully", 'success'));
        } catch (error) {
            dispatch(OApp.showToast("Something went wrong. Please try again.", 'error'));
        }
    }



    publishLTDDoc = (docId: string, shouldPublish: boolean) => async dispatch => {
        try {
            const res = await utilities.request({
                url: `ltddocs/${docId}/publish`,
                method: 'POST',
                params: {
                    publish: shouldPublish
                }
            });
            dispatch({
                type: this.actions.LTD_DOC_PUBLISH,
                data: res.data
            })
            dispatch(OApp.showToast(shouldPublish ? 'Document published' : 'Document unpublished', 'success'))
            return res.data;
        } catch (error) {
            dispatch(OApp.showToast('Something went wrong', 'error'));
            return {}
        }
    }


    closeDialog = () => dispatch => dispatch({
        type: this.actions.CLOSE_NEW_LTD_DOCUMENT_DIALOG
    })

    // CREATE NEW LTD DOCUMENT - TRANSLATE A DOCUMENT
    openPrefilledDialog = (parentNodeId: string, formData: any) => async (dispatch, getState) => {
        const { App } = getState() as TState;
        const { ltdIncludeOptions } = App;
        let options: any;
        if (_.isEmpty(ltdIncludeOptions))
            options = await includeValueOptions();
        else options = ltdIncludeOptions;

        dispatch({
            type: this.actions.SET_INCLUDE_FIELD_ID_OPTIONS,
            data: options
        })

        dispatch({
            type: this.actions.OPEN_NEW_LTD_PREFILLED_DOCUMENT_DIALOG,
            data: {
                parentNodeId,
                formData
            }
        })
        dispatch(this.fetchDocPath(parentNodeId));
    }

    // CREATE NEW LTD DOCUMENT 
    openDialog = () => async (dispatch, getState) => {
        // console.log('Open document dialog', parentNodeId);
        const { NewLTDDocument, App } = getState() as TState;
        const { ltdIncludeOptions } = App;
        let options: any;
        if (_.isEmpty(ltdIncludeOptions))
            options = await includeValueOptions();
        else options = ltdIncludeOptions;
        const parentNodeId = _.get(NewLTDDocument, 'parentNodeId');

        dispatch({
            type: this.actions.SET_INCLUDE_FIELD_ID_OPTIONS,
            data: options
        })

        dispatch({
            type: this.actions.OPEN_NEW_LTD_DOCUMENT_DIALOG,
        })
        dispatch(this.fetchDocPath(parentNodeId));
    }


    openDocumentSelectionDialog = (parentNodeId: string) => dispatch => {
        dispatch({
            type: this.actions.OPEN_LTD_DOCUMENT_SELECTION_DIALOG,
            data: parentNodeId
        })
    }
    closeDocumentSelectionDialog = () => dispatch => dispatch({
        type: this.actions.CLOSE_LTD_DOCUMENT_SELECTION_DIALOG
    })



}


export const ONewLTDDocument = new NewLTDDocument();

