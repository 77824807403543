import React, { Component, useEffect, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import AppNavigation from '../Route/AppNavigation';
import AppHeader from '../Components/AppHeader/AppHeader';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Theme from '../Resources/Theme';
import AuthDialog from '../Dialogs/AuthDialog';
import DetailPage from './DetailPage';
import { OAuth } from '../Features/Authentication/redux-config';
import utilities from '../Resources/Utils';
import Toast from '../Components/Toast';
import { OReadingList } from '../Features/ReadingList/redux-config';
import AdvancedSearchDialog from '../Dialogs/AdvancedSearchDialog';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
// import AddNewLegalFinding from '../Components/NewLegalFinding'
// import NewLegalFindingDialog from '../Dialogs/NewLegalFindingDialog';
// import AddNewLTDDocument from '../Components/NewLTDDocument';
// import NewLTDDocument from '../Dialogs/NewLTDDocument';
import { OApp } from '../RootReducer/AppReducer';
import { AxiosPromise } from 'axios';
import { LinearProgress } from '@material-ui/core';
import Sidebar from '../Components/Sidebar';
import LtdSurveyDialog from '../Features/LtdSurvey/LtdSurveyDialog';
import { AppContext } from '../Contexts/AppContext/index';
import BatchEdit from '../Components/BatchEdit';
import BatchEditDialog from '../Dialogs/BatchEditDialog';
import BatchReviewDialog from '../Dialogs/BatchReviewDialog';
import NewDecisionDialog from '../Components/NewDecision';
import { isCld, isScc } from '../Resources/constants';
import FullscreenLoader from '../Components/FullscreenLoader';
import { ODocuments } from '../Features/LTDDocuments/redux-config';

const NewLegalFindingDialog = React.lazy(() => import('../Dialogs/NewLegalFindingDialog'));
const NewLTDDocument = React.lazy(() => import('../Dialogs/NewLTDDocument'));
const AddNewLegalFinding = React.lazy(() => import('../Components/NewLegalFinding'));
const AddNewLTDDocument = React.lazy(() => import('../Components/NewLTDDocument'));

interface Props extends WithStyles<typeof STYLES>, RouteComponentProps<any>, IDispatchProps, IStateProps {}

interface IDispatchProps {
    initApp: () => Promise<void>;
    resetFilter: (variant: 'scc' | 'ltd') => void;
}

interface IStateProps {
    appInit: boolean;
    loading: boolean;
}

export const HEADER_HEIGHT = 50;

export let HISTORY;

const RootLayout: React.FC<Props> = props => {
    const { context, setContext } = useContext(AppContext);

    useEffect(() => {
        HISTORY = props.history;
        props.initApp();
    }, []);

    useEffect(() => {
        // const regEx = /^\/(cld|decision|clddoc|cld-dashboard)/g
        // const testCld = regEx.test(window.location.pathname);
        const testCld = isCld(window.location.pathname);
        const testScc = isScc(window.location.pathname);

        if (window.location.pathname === '/') {
            // props.resetFilter('ltd');
        }

        if (window.location.pathname === '/scc') {
            props.resetFilter('scc');
        }

        if (testCld && context !== 'cld') setContext('cld');
        else if (testScc && context !== 'scc') setContext('scc');
        else if (context !== 'ltd' && !testCld && !testScc) setContext('ltd');
    }, [props.location.pathname]);

    useEffect(() => {
        if (props.appInit) {
            const loaderEl = document.getElementById('loader');
            if (loaderEl && loaderEl.remove) loaderEl.remove();
        }
    }, [props.appInit]);

    const { classes, appInit = false, loading } = props;

    return appInit ? (
        <React.Suspense fallback={<LinearProgress color='primary' />}>
            {loading && <FullscreenLoader />}
            <div className={isScc(window.location.pathname) ? classes.sccRoot : classes.root}>
                <AppHeader />
                <div className={classes.content}>
                    <AppNavigation />
                    <DetailPage />
                    <Toast />
                </div>
                <AuthDialog />
                <ConfirmationDialog />
                <AdvancedSearchDialog />
                <Sidebar />
                <NewLegalFindingDialog />
                <AddNewLegalFinding />
                <NewLTDDocument />
                <AddNewLTDDocument />
                <BatchEditDialog />
                <BatchReviewDialog />
                <LtdSurveyDialog />
                <NewDecisionDialog />
            </div>
        </React.Suspense>
    ) : (
        <LinearProgress />
    );
};

// class RootLayout extends Component<Props> {
//     state = {
//         appInit: false,
//     }
//     componentDidMount = () => {
//         HISTORY = this.props.history;
//         this.props.initApp();
//     }

//     componentDidUpdate = (prevProps, prevState) => {

//         if (!prevProps.appInit && this.props.appInit) {
//             const loaderEl = document.getElementById('loader');
//             if (loaderEl && loaderEl.remove)
//                 loaderEl.remove();
//         }
//     }

//     render() {
//         const { classes, appInit = false } = this.props;

//         return appInit ? (
//             <React.Suspense fallback={<LinearProgress />}>
//                 <div className={classes.root}>
//                     <AppHeader />
//                     <div className={classes.content}>
//                         <AppNavigation />
//                         <DetailPage />
//                         <Toast />
//                     </div>
//                     <AuthDialog />
//                     <ConfirmationDialog />
//                     <AdvancedSearchDialog />
//                     <Sidebar />
//                     <NewLegalFindingDialog />
//                     <AddNewLegalFinding />
//                     <NewLTDDocument />
//                     <AddNewLTDDocument />
//                 </div >
//             </React.Suspense>
//         ) : <LinearProgress />
//     }
// }

const mapStateToProps = (state: any) => ({
    ..._.pick(state.App, ['appInit', 'loading']),
});

const mapDispatchToProps = (dispatch: any) => ({
    initApp: async () => dispatch(OApp.init()),
    resetFilter: variant => dispatch(ODocuments.resetFilters(variant)),
});

const STYLES = theme =>
    createStyles({
        root: {
            background: Theme.Colors.Grayscale.Grey5,
        },
        sccRoot: {
            background: Theme.Colors.Grayscale.White,
        },
        content: {
            paddingTop: HEADER_HEIGHT,
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            overflow: 'hidden',
        },
    });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(RootLayout)));
