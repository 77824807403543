import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    Grid,
    CircularProgress,
    LinearProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Theme from '../../Resources/Theme';
import Folder from './Folder';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { TReadingList } from '../../Features/ReadingList/@types';
import { OReadingList } from '../../Features/ReadingList/redux-config';
import { OApp } from '../../RootReducer/AppReducer';
import MetaTag from '../../Components/MetaTag';

interface Props extends WithStyles<typeof STYLES> {}

interface IDispatchProps {
    fetchReadingLists: Function;
    deleteReadingList: (id) => Promise<any>;
    updateReadingList: (id, formData) => Promise<any>;
    confirmDialog: (msg: string, onConfirm: Function, onCancel: Function) => void;
}

interface IStateProps {
    userId: string;
    readingList: Array<TReadingList> | undefined;
}

class ReadingList extends Component<Props & IStateProps & IDispatchProps> {
    state = {
        documentId: undefined,
    };

    componentDidMount = () => {
        if (!_.isEmpty(this.props.userId)) this.props.fetchReadingLists(this.props.userId);
    };
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.userId !== this.props.userId && !_.isEmpty(this.props.readingList))
            this.props.fetchReadingLists(this.props.userId);
    };

    confirmDelete = () => {
        this.props.deleteReadingList(this.state.documentId);
        this.setState({ documentId: undefined });
    };

    cancelDelete = () => {
        this.setState({ documentId: undefined });
    };

    onFolderDelete = (id: string) => {
        this.setState({ documentId: id });
        this.props.confirmDialog(
            'Are you sure you want to delete the reading list? This cannot be undone.',
            this.confirmDelete,
            this.cancelDelete
        );
    };

    onFolderRename = (id: string, formData) => {
        this.props.updateReadingList(id, formData);
    };

    handlePrint = (id: string) => {
        window.open(`/reading-list/print/${id}`, '_blank');
    };

    render() {
        const { classes, readingList } = this.props;
        return (
            <div className={classes.root}>
                <MetaTag title='Reading list' />
                {/* <div className={classes.header}>
                    <Typography>MY READING LIST</Typography>
                    <div>
                        <Typography>Sorted By:&nbsp;</Typography>
                        <Typography>Recently Added</Typography>
                    </div>
                </div> */}

                {_.isUndefined(readingList) ? <LinearProgress color='secondary' style={{ width: '100%' }} /> : null}
                <div className={classes.readingListContainer}>
                    <Grid container className={classes.grid}>
                        {_.map(readingList, details => (
                            <Grid key={details.id} item md={3}>
                                <Folder
                                    details={details}
                                    onDelete={this.onFolderDelete}
                                    onRename={this.onFolderRename}
                                    onPrint={this.handlePrint}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userId: _.get(state.Auth, 'user.id'),
    readingList: _.get(state.ReadingList, 'readingList'),
});

const mapDispatchToProps = dispatch => ({
    fetchReadingLists: id => dispatch(OReadingList.fetchReadingLists(id)),
    deleteReadingList: id => dispatch(OReadingList.deleteReadingList(id)),
    updateReadingList: (id, formData) => dispatch(OReadingList.updateReadingList(id, formData)),
    confirmDialog: (msg, onConfirm, onCancel) => dispatch(OApp.showConfirmationDialog(msg, onConfirm, onCancel)),
});

const STYLES = theme =>
    createStyles({
        root: {},
        header: {
            height: 50,
            display: 'flex',
            padding: '0px 15px',
            alignItems: 'center',
            justifyContent: 'space-between',
            // background: Theme.Colors.Secondary.main,
            background: Theme.Colors.Fourth,
            boxShadow: `0px 1px 3px ${fade(Theme.Colors.Grayscale.Black, 0.16)}`,
            '& p': {
                color: 'white',
            },
            '& > div': {
                display: 'flex',
            },
        },
        readingListContainer: {
            boxSizing: 'border-box',
            padding: '50px 0px',
            height: 'calc(100vh - 50px)',
            overflow: 'auto',
        },
        grid: {
            width: 1200,
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '95vw',
                '& > div': {
                    width: '100%',
                },
            },
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(ReadingList));
